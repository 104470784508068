type Env = {
  valid: boolean;
  readonly environment: string;
  readonly endpointSearch: string;
  version: string;
  loginUrl: string;
  logoutUrl: string;

  productName: string;
};

declare global {
  interface Window {
    appenv?: Env;
  }

  interface Document {
    documentMode?: any;
  }
}

const env: Env = {
  valid: false,
  environment: "development",
  endpointSearch: "http://localhost:5000/",
  version: "0.0.0",
  loginUrl: "https://login.landaumedia.de/Login/Login?app=WEW8XQTB",
  logoutUrl: "https://login.landaumedia.de/Login/Logout?app=WEW8XQTB",

  productName: "Explore",
};

// mediaAccountEnv kommt vom /env und wird als erstes von der index.html geladen.
if (window.appenv) {
  Object.assign(env, window.appenv);
  env.valid = true;
}

export default env;
