import { makeStyles } from "@material-ui/core";
import Logo from "icons/Logo";
import env from "env";
import { t } from "@lingui/macro";

export default function Footer() {
  const classes = useStyles();
  const version = env.version;
  return (
    <footer className={classes.footerHaupt}>
      <div className={classes.footerSpace}>
        <li className={classes.footerLinks}>
          <div className={classes.logoContainer}>
            <a href="https://www.landaumedia.de/">
              <Logo />
            </a>
          </div>
        </li>

        <li className={classes.footerLinks}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.landaumedia.de/wp-content/uploads/2019/01/Landau_Media_AGBs_GmbH_01_2019.pdf"
          >
            {t({
              id: "footer.agb",
              message: "AGB",
            })}
          </a>
        </li>
        <li className={classes.footerLinks}>
          <a target="_blank" rel="noreferrer" href="https://www.landaumedia.de/impressum/">
            {t({
              id: "footer.impressum",
              message: "Impressum",
            })}
          </a>
        </li>
        <li className={classes.footerLinks}>
          <a target="_blank" rel="noreferrer" href="https://www.landaumedia.de/datenschutz/">
            {t({
              id: "footer.datenschutz",
              message: "Datenschutz",
            })}
          </a>
        </li>
      </div>

      <div className={classes.footerSpace}>
        <li className={classes.footerLinks}>{version}</li>
      </div>
    </footer>
  );
}

const useStyles = makeStyles({
  footerHaupt: {
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: "35px",
    paddingRight: "35px",
    overflow: "hidden",
    width: "100%",
    color: "#7a7a7a",
  },
  footerSpace: {
    display: "flex",
  },
  footerLinks: {
    paddingTop: 8,
    paddingBottom: 8,
    marginRight: 16,
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    display: "flex",
    "&hover": {
      color: "#3E3E3E",
    },
  },
  logoContainer: {
    width: 87,
    height: 44,
  },
});
