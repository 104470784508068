import { makeStyles, Typography } from "@material-ui/core";

export type InfoProps = {
  headline: string;
  hashtags: string[];
};

export default function DetailseiteInfo(props: InfoProps) {
  const classes = useStyle();
  //Width muss noch angepasst werden + alles Responsive

  if (!props.hashtags || props.hashtags?.length === 0) return <></>;
  return (
    <div className={classes.container}>
      <Typography className={classes.headline}>{props.headline}</Typography>
      <br />
      {props.hashtags?.map((hashtag) => (
        <span className={classes.hashtag}>
          <span className={classes.hashtagContent}>{hashtag}</span>
        </span>
      ))}
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    borderBottom: "1px solid lightGray",
    marginRight: 10,
    paddingBottom: 10,
  },
  headline: {
    color: "#00C1BE",
    textTransform: "uppercase",
    marginBottom: 5,
  },
  dict: {
    fontSize: 10,
  },
  hashtag: {
    display: "inline-flex",
    border: "none",
    borderRadius: 5,
    margin: "1px 2px",
    padding: "0px 5px",
    lineHeight: 1.75,
    height: 21,
    color: "inherit",
    backgroundColor: "rgb(242, 242, 242)",
    overflow: "hidden",
    WebkitBoxAlign: "center",
    alignItems: "center",
    position: "relative",
  },
  hashtagContent: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignSelf: "center",
  },
});
