import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export type MeldungNavigationStateType = {
  currentIndex: number;
};

const initialState: MeldungNavigationStateType = {
  currentIndex: 0,
};

export const meldungNavigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    navigateForward: (state) => {
      state.currentIndex += 1;
    },
    navigateBackward: (state) => {
      state.currentIndex -= 1;
    },
    setNavigationIndex: (state, action) => {
      state.currentIndex = action.payload;
    },
    resetNavigationIndex: (state) => {
      state.currentIndex = 0;
    },
  },
});

export const {
  navigateForward,
  navigateBackward,
  setNavigationIndex,
  resetNavigationIndex,
} = meldungNavigationSlice.actions;

export const selectMeldungNavigationState = (state: RootState) =>
  state.meldungNavigation;

export default meldungNavigationSlice.reducer;
