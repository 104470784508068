import { makeStyles, Paper } from "@material-ui/core";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { DatenpunkteTitel } from "requests/aggregationRequests";
import { format } from "numerable";
import { de } from "numerable/locale";

type Props = {
  title: string;
  color: string;
  data: DatenpunkteTitel[];
  dataTitelBool: boolean;
  xAxisDataKey: string;
  yAxisDataKey: string;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.tooltip}>
        Reichweite: {payload[0].value.toLocaleString()}
      </div>
    );
  }

  return null;
};

export default function TitelChart(props: Props) {
  const classes = useStyles();

  const formatWerte = (item: any) => format(item, "0,000 a", { locale: de });

  return (
    <Paper elevation={2} className={classes.mainContainer}>
      <div className={classes.header}>{props.title}</div>
      <ResponsiveContainer width="100%" height={600}>
        <BarChart
          width={1000}
          height={1000}
          data={props.data}
          layout={"vertical"}
        >
          <XAxis type="number" tickFormatter={formatWerte} />
          <YAxis
            orientation="left"
            dataKey={props.yAxisDataKey}
            type="category"
            yAxisId="1"
            width={200}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            yAxisId="1"
            type="natural"
            fill="#ff0004"
            dataKey={props.xAxisDataKey}
          />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
}

const useStyles = makeStyles({
  mainContainer: {
    borderRadius: "10px",
    padding: 20,
    paddingTop: 30,
  },

  tooltip: {
    background: "white",
    padding: "5px",
  },

  label: {
    background: "white",
  },

  header: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: "16px",
    marginTop: "0px",
    color: "#4d4d4d",
  },
});
