import { Popover } from "@material-ui/core";

type Props = {
  children: JSX.Element;
  open: boolean;
  anchorEL: HTMLElement;
  handleClose: () => void;
};

export default function FilterChipPopover(props: Props) {
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEL}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {props.children}
    </Popover>
  );
}
