import { makeStyles, Snackbar } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import clsx from "clsx";
import { useAppDispatch } from "redux/store";
import { resetToast } from "redux/toast";
import { primaryColor, secondaryColor } from "theme";

type ToastProps = {
  open: boolean;
  variant: "success" | "warning" | "error" | "info";
  text: string;
};

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function Toast(props: ToastProps) {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  let variantStyle = classes.variantInfo;
  let autoHideDuration = 2000;
  switch (props.variant) {
    case "success":
      variantStyle = classes.variantSuccess;
      break;
    case "warning":
      variantStyle = classes.variantWarning;
      autoHideDuration = 5000;
      break;
    case "error":
      variantStyle = classes.variantError;
      autoHideDuration = 5000;
      break;
    default:
      variantStyle = classes.variantInfo;
      break;
  }

  const textStyle = clsx(classes.text, variantStyle);

  return (
    <div>
      <Snackbar
        open={props.open}
        autoHideDuration={autoHideDuration}
        onClose={() => dispatch(resetToast())}
        className={classes.snackbar}
        TransitionComponent={TransitionUp}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <div className={textStyle}>{props.text}</div>
      </Snackbar>
    </div>
  );
}

const useStyles = makeStyles({
  snackbar: {
    bottom: "8px",
    zIndex: 100000,
  },
  text: {
    width: "600px",

    display: "flex",
    justifyContent: "center",
    height: "80px",
    alignItems: "center",
  },
  variantInfo: {
    background: primaryColor,
    color: secondaryColor,
  },
  variantWarning: {
    background: "#FF9800",
    color: secondaryColor,
  },
  variantError: {
    background: "#F44336",
    color: secondaryColor,
  },
  variantSuccess: {
    background: "#4CAF50",
    color: secondaryColor,
  },
});
