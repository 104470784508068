import { makeStyles } from "@material-ui/core";
import RefreshIconExtern from "@material-ui/icons/Refresh";

export default function RefreshIcon() {
  const classes = useStyles();

  return <RefreshIconExtern fontSize="small" className={classes.root} />;
}

const useStyles = makeStyles({
  root: {
    transform: "scaleX(-1) rotate(0.0turn)",
  },
});
