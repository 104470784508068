import {
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";

type KeyValuePair = {
  bezeichner: string;
  name: string;
};

type RadioGroupProps = {
  elements: KeyValuePair[];
  selectedValue: string;
  onChange: (value: string) => void;
  header: string;
};

export default function Radios(props: RadioGroupProps) {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <FormLabel component="legend" className={classes.header}>
        {props.header}
      </FormLabel>
      <RadioGroup
        value={props.selectedValue}
        onChange={(e) => props.onChange(e.target.value)}
      >
        {props.elements.map((element: KeyValuePair, i: number) => (
          <FormControlLabel
            key={`${element.bezeichner}-${i}`}
            label={element.name}
            value={element.bezeichner}
            onChange={() => props.onChange(element.bezeichner)}
            control={<Radio color="primary" size="small" />}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    padding: 5,
    borderBottom: "1px solid lightGray",
  },
  header: {
    fontWeight: "bold",
    marginBottom: 5,
    marginTop: 5,
  },
});
