import { IconButton } from "@material-ui/core";
import KachelnIcon from "icons/KachelnIcon";

type Props = {
  selected: boolean;
  onSelect: () => void;
};

export default function KachelnButton(props: Props) {
  return (
    <IconButton onClick={props.onSelect}>
      <KachelnIcon selected={props.selected} />
    </IconButton>
  );
}
