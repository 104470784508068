import { MeldungView } from "types/MeldungView";

export function getDemoMeldung(): MeldungView {
  return {
    id: "landaumedia/activity/opoint/161993_336979",
    meldungDetailUrl:
      "meldung/bGFuZGF1bWVkaWEvYWN0aXZpdHkvb3BvaW50LzE2MTk5M18zMzY5Nzk=",
    empfangen: "11.01.2022 13:54",
    veroeffentlicht: "11.01.2022 13:53",
    representantBildUrl: "https://www.saechsische.de/img/googlenews.png",
    direktUrl:
      "https://www.saechsische.de/kamenz/kamenz-rathaus-erreichbarkeit-eingeschraenkt-telefonanlage-technische-arbeiten-5602748.html",
    medienart: {
      name: "Tageszeitung",
      nameMitGattung: "Tageszeitung (Online)",
      bezeichner: "onlinetageszeitung",
      gattungBezeichner: "onlinemedien",
      gattung: "Print",
      standardInSuche: true,
    },
    sprache: {
      bezeichner: "de",
      name: "Deutsch",
    },
    herkunftsland: {
      bezeichner: "de",
      name: "Deutschland",
      kontinentName: {
        bezeichner: "europa",
        name: "Europa",
      },
    },
    previewTextHtml:
      "Am 13. und 14. <span class='mark'>Januar</span> sind technische Arbeiten an der Telefonanlage der Stadtverwaltung geplant. Nur für wichtige Anliegen gibt es alternative... Rufnummern. \r\nKamenz. Aufgrund umfassender technischer Arbeiten an der Telefonanlage am 13. und 14. <span class='mark'>Januar</span> ist die Stadtverwaltung Kamenz in diesem Zeitraum...",
    previewHeadlineHtml: "Kamenz: Rathaus nur eingeschränkt erreichbar...",
    tags: null,
    kategorien: null,
    quelle: {
      quelleLink: "https://www.saechsische.de/kamenz",
      quelleTitel: "Sächsische.de",
      quelleInformation: "Info",
    },
    exportInformation: {
      exportDatum: null,
      exportStatus: "None",
    },
    mediengattung: {
      bezeichner: "",
      name: "",
    },
    headlineHtml: "",
    meldungHtml: "",
    printartikelinformation: "",
    medium: {
      id: "",
      name: "",
      webseite: "",
      onlineVisits: "",
      onlinePageImpressions: "",
      reichweite: "",
      printGedruckteAuflage: "",
      printVerbreiteteAuflage: "",
      printVerkaufteAuflage: "",
      twitterFollower: "",
      medienart: {
        bezeichner: "",
        gattungBezeichner: "",
        nameMitGattung: "",
        name: "",
        gattung: "",
        standardInSuche: false,
      },
      gattung: {
        bezeichner: "",
        name: "",
      },
      bundesland: {
        bezeichner: "",
        name: "",
      },
      herkunftsland: {
        bezeichner: "",
        name: "",
        kontinentName: {
          bezeichner: "",
          name: "",
        },
      },
      sprache: {
        bezeichner: "",
        name: "",
      },
      nielsengebiet: {
        name: "",
        bezeichner: "",
      },
      erscheinungsweise: {
        bezeichner: "",
        name: "",
      },
      wemfgebiet: {
        bezeichner: "",
        name: "",
        gebiet: "",
        information: "",
      },
      verlag: {
        name: "",
        anschrift: "",
      },
      verlaginformationen: "",
      redaktion: {
        email: "",
        chefredakteur: "",
        anschrift: "",
        telefon: "",
        fax: "",
      },
      redaktioninformation: "",
      webseiteinformation: "",
      regionalitaetinformation: "",
      auflageinformation: "",
    },
    autor: {
      autorName: "",
      autorHomepage: "",
      autorAnzeigeName: "",
      autorinformation: "",
    },
    mediaAttachments: [],
    engagements: {
      anzahlComments: "",
      anzahlDislikes: "",
      anzahlLikes: "",
      anzahlShares: "",
      anzahlVideoViews: "",
    },
    reichweite: "",
    anzeigenAequivalenzWert: "",
    printInformationen: {
      groesseinformation: "",
      platzierunginformation: "",
      farbigkeitinformation: "",
      seitename: "",
    },
  };
}
