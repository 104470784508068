import { Popover } from "@material-ui/core";
import AuftragsauswahlPanel from "components/AuftragsauswahlPanel";
import { selectMeldungenState } from "redux/meldungen";
import { useAppSelector } from "redux/store";
import { Auftrag } from "types/Auftrag";

type Props = {
  onExport: (auftragsId: string, labels: string[]) => void;
  handleClose: () => void;
  exportLength: number;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  auftraege: Auftrag[];
  letzterAuftrag: Auftrag;
};

export default function AuftraegePopover(props: Props) {
  const { exportStatus } = useAppSelector(selectMeldungenState);

  const { open, anchorEl, handleClose } = props;

  const id = open ? "simple-popover" : undefined;

  const handleExport = (auftrag: Auftrag, labels: string[]) => {
    props.onExport(auftrag.id, labels);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{ left: "0px" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <AuftragsauswahlPanel
        onCancel={() => handleClose()}
        onExport={(a, l) => handleExport(a, l)}
        exportStatus={exportStatus}
        auftraege={props.auftraege}
        meldungsCount={props.exportLength}
        selectedAuftrag={props.letzterAuftrag}
      />
    </Popover>
  );
}
