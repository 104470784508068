export const EXPORT_MELDUNGEN = `mutation exportMeldungen($exportMeldungenInput: ExportMeldungenInput!){
    exportMeldungen(exportMeldungenInput: $exportMeldungenInput) {
      meldungen {
        id
        meldungDetailUrl
        exportInformation{
          exportStatus
          exportDatum
        }
      }
      erfolgreich
    }
  }`;

export const TOGGLE_MELDUNG = `mutation toggleMeldung($meldungsId: String!){
  toggleMeldung(meldungsId: $meldungsId)
}`;

export const DELETE_MELDUNG = `mutation deleteMeldung($meldungsId: String!){
  deleteMeldung(meldungsId: $meldungsId)
}`;

export const TOGGLE_ALL_MELDUNGEN = `mutation toggleAllMeldungen ($search: SearchInput, $areSomeSelected: Boolean!){
  toggleAllMeldungen(search: $search, areSomeSelected: $areSomeSelected)
}`;

export const EXPORT_LISTE_LEEREN = `mutation exportListeLeeren{
  exportListeLeeren
}`;

export const RESOLVE_QUERY = `mutation resolveQuery($queryString: String!) {
  resolveQuery(queryString: $queryString)  {
    modifier 
    query
    feld 
    bedingung
  }
}`;
