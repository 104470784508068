import React from "react";
import styled from "styled-components/macro";

type Props = { text: string; image: string; children?: React.ReactNode };

const MessageContainer = styled.div`
  margin: 100px auto 0 auto;
  text-align: center;
  display: table;
  text-transform: uppercase;
`;

export function AppLayoutMessage({ text, image, children }: Props) {
  return (
    <MessageContainer>
      <h3>{text}</h3>
      <div style={{ margin: "0 auto", padding: "20px" }}>
        {image && <img src={image} alt={text} />}
      </div>
      {children && (
        <div style={{ padding: "0 30px", display: "inline-block" }}>
          {children}
        </div>
      )}
    </MessageContainer>
  );
}
