import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  exportListeLeerenGQL,
  exportMeldungenGQL,
  getMeldungBySearchQueryGQL,
  getNextMeldungBySearchQueryGQL,
  toggleAllMeldungenGQL,
  toggleMeldungGQL,
} from "requests/meldungenRequests";
import { SearchInput } from "types/Query";
import { RootState } from "./store";
import { FilterState } from "./filter";
import { LocaleTag } from "types/I18n";
import { getZeitraumGrundlage } from "./zeitraumGrundlagen";
import { MeldungView } from "types/MeldungView";
import { ExportMeldungResult } from "types/ExportMeldungResult";
import { ContainerStateType } from "./container";

export type MeldungenStateType = {
  elements: MeldungView[];
  status: "idle" | "loading" | "failed" | "mehr-loading";
  toggleStatusId: string;
  exportStatus: "idle" | "loading" | "failed";
  formattedCount: string;
  count: number;
  nextLink: string | null;
};

export function BuildQueryFromFilterState(
  filter: FilterState,
  from: number = 0,
  count: number
): SearchInput {
  return {
    query: {
      count: count,
      zeitraum: {
        von: filter.zeitpunktVon,
        bis: filter.zeitpunktBis,
        vordefinierterZeitpunkt: getZeitraumGrundlage().find(
          (x) => x.key === filter.zeitraumGrundlage
        )?.enumValue,
      },
      spracheBezeichner: filter.sprachen ?? [],
      herkunftsBezeichner: filter.herkunftslaender ?? [],
      medienartBezeichner: filter.medienarten ?? [],
      felder: filter.suchFelder,
    },
  };
}

export const getMeldungen = createAsyncThunk(
  "meldungen/getMeldungen",
  async (props: {
    searchQuery: SearchInput;
    locale: LocaleTag;
    containerState: ContainerStateType;
  }) => {
    const data = await getMeldungBySearchQueryGQL(
      props.searchQuery,
      props.locale
    );
    return data;
  }
);

export const getNextMeldungen = createAsyncThunk(
  "meldungen/getNextMeldungen",
  async (props: { nextLink: string; locale: LocaleTag }) => {
    const data = await getNextMeldungBySearchQueryGQL(
      props.nextLink,
      props.locale
    );
    return data;
  }
);

export const toggleAllMeldungen = createAsyncThunk(
  "meldungen/toggleAllMeldungen",
  async (props: {
    searchQuery: SearchInput;
    areSomeSelected: boolean;
    locale: LocaleTag;
  }): Promise<Boolean> => {
    const data = await toggleAllMeldungenGQL(props);
    return data;
  }
);

export const toggleMeldung = createAsyncThunk(
  "meldungen/toggleMeldung",
  async (meldung: MeldungView) => {
    const data = await toggleMeldungGQL(meldung.meldungDetailUrl);
    if (data) return meldung;
    return null;
  },
  {}
);

export const exportMeldungen = createAsyncThunk(
  "meldungen/export",
  async (props: {
    meldungen: string[];
    auftragsId: string;
    labels: string[];
  }) => {
    const result = await exportMeldungenGQL({
      auftragsId: props.auftragsId,
      meldungsIds: props.meldungen,
      labels: props.labels,
    });

    // if (!result.erfolgreich) throw new Error("Ein Fehler ist abgetreten!");

    return result;
  }
);

export const exportListeLeeren = createAsyncThunk(
  "meldungen/exportListeLeeren",
  async (props:{}): Promise<Boolean> => {

    const data = await exportListeLeerenGQL();
    return Boolean(data);
  }
);

const initialState: MeldungenStateType = {
  elements: [],
  status: "idle",
  exportStatus: "idle",
  toggleStatusId: "",
  formattedCount: "0",
  nextLink: null,
  count: 0,
};

export const meldungenSlice = createSlice({
  name: "meldungen",
  initialState: initialState,
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleMeldung.pending, (state, action) => {
        state.toggleStatusId = action?.meta?.arg?.id ?? "";
      })
      .addCase(toggleMeldung.rejected, (state) => {
        state.toggleStatusId = "";
      })
      .addCase(
        toggleMeldung.fulfilled,
        (state, action: PayloadAction<MeldungView>) => {
          state.toggleStatusId = "";
        }
      )
      .addCase(getMeldungen.pending, (state) => {
        state.status = "loading";
        state.nextLink = null;
      })
      .addCase(getMeldungen.fulfilled, (state, action) => {
        state.elements = action.payload.meldungen;

        state.count = action.payload.anzahlGesamt;
        state.formattedCount = action.payload.anzahlGesamtFormattiert;
        state.status = "idle";
        state.nextLink = action.payload.nextLink;
      })
      .addCase(getMeldungen.rejected, (state) => {
        state.elements = [];
        state.formattedCount = "0";
        state.count = 0;
        state.status = "failed";
        state.nextLink = null;
      })
      .addCase(getNextMeldungen.fulfilled, (state, action) => {
        const ids = new Set(state.elements.map((d) => d.id));
        state.elements = [
          ...state.elements,
          ...action.payload.meldungen.filter(
            (d: MeldungView) => !ids.has(d.id)
          ),
        ];
        state.count = action.payload.anzahlGesamt;
        state.status = "idle";
        state.nextLink = action.payload.nextLink;
      })
      .addCase(getNextMeldungen.pending, (state) => {
        state.status = "mehr-loading";
        state.nextLink = null;
      })
      .addCase(getNextMeldungen.rejected, (state, action) => {
        state.status = "failed";
        state.nextLink = null;
      })
      .addCase(exportMeldungen.pending, (state) => {
        state.exportStatus = "loading";
      })
      .addCase(
        exportMeldungen.fulfilled,
        (state, action: PayloadAction<ExportMeldungResult>) => {
          state.exportStatus = "idle";

          if (!action.payload.erfolgreich) return;

          const exportedMeldungen = action.payload.meldungen;
          const meldungen = state.elements;

          meldungen.forEach((x) => {
            const found = exportedMeldungen.find(
              (a) => a.meldungDetailUrl === x.meldungDetailUrl
            );
            if (found) x.exportInformation = found.exportInformation;
          });

          state.elements = meldungen;
        }
      )
      .addCase(exportMeldungen.rejected, (state) => {
        state.exportStatus = "failed";
      })
  },
});


export const selectMeldungenState = (state: RootState) => state.meldungen;

export default meldungenSlice.reducer;
