import { GET_ZEIT_AGGREGATION } from "./aggregations";
import { gqlClient } from "./client";
import { gql } from "@apollo/client";
import { Query } from "types/Query";
import { LocaleTag } from "types/I18n";

export type BatchAggregationInput = {
  query: Query;
  zeit: boolean;
  gesamt: boolean;
  titel: boolean;
};

export type DatenpunkteZeit = {
  datum: string;
  anzahlMeldungen: number;
  reichweiteGesamt: number;
};

export type DatenpunkteTitel = {
  bezeichnerWert: string;
  titelName: string;
  wertAggregation: number;
};

type ZeitAggregation = {
  datenpunkte: DatenpunkteZeit[];
};

type TitelAggregation = {
  datenpunkt: DatenpunkteTitel[];
};

export type BatchAggregationResponse = {
  zeit: ZeitAggregation;
  titel: TitelAggregation;
};

export async function getBatchAggregationGQL(
  batchAggregationInput: BatchAggregationInput,
  localeTag: LocaleTag
): Promise<BatchAggregationResponse> {
  const result = await gqlClient.query({
    query: gql(GET_ZEIT_AGGREGATION),
    variables: {
      batchAggregationArgs: batchAggregationInput,
      localeTag: localeTag,
    },
  });

  return result.data.batchAggregation as BatchAggregationResponse;
}
