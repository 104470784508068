import { getFormatter } from "./intlFormat";
import { LocaleTag } from "types/I18n";

export type Calendar = {
  firstDayOfWeek: number;
  formatter: Intl.DateTimeFormat;

  weekdays: {
    narrow: string[];
    short: string[];
    long: string[];
  };

  months: {
    narrow: string[];
    short: string[];
    long: string[];
  };
};

const calendars: { [K in LocaleTag]: Partial<Calendar> } = {
  "de-DE": {},
  "de-CH": {},
  "en-GB": {},
  "en-US": { firstDayOfWeek: 0 },
  fr: {},
};

export const getCalendar = (localeName: LocaleTag): Calendar => {
  if (calendars[localeName] && calendars[localeName].weekdays)
    return calendars[localeName] as Calendar;
  const formatter = getFormatter(localeName);

  const cal: Calendar = {
    weekdays: [1, 2, 3, 4, 5, 6, 7].reduce(
      (acc, n) => {
        const date = new Date();
        date.setUTCFullYear(2019, 3, n);
        date.setUTCHours(0, 0, 0, 0);
        acc.narrow.push(formatter.weekdayNarrow.format(date));
        acc.short.push(formatter.weekdayShort.format(date));
        acc.long.push(formatter.weekdayLong.format(date));
        return acc;
      },
      { narrow: [] as string[], short: [] as string[], long: [] as string[] }
    ),

    months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].reduce(
      (acc, n) => {
        const date = new Date();
        date.setUTCFullYear(2019, n, 1);
        date.setUTCHours(0, 0, 0, 0);
        acc.narrow.push(formatter.monthNarrow.format(date));
        acc.short.push(formatter.monthShort.format(date));
        acc.long.push(formatter.monthLong.format(date));
        return acc;
      },
      { narrow: [] as string[], short: [] as string[], long: [] as string[] }
    ),

    formatter: formatter.shortNumericDate,
    firstDayOfWeek: 1,

    ...(calendars[localeName] ? calendars[localeName] : {}),
  };

  calendars[localeName] = cal;
  return cal;
};

export const currentCalendar = (locale: LocaleTag) => getCalendar(locale);
