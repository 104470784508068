import { Paper, makeStyles } from "@material-ui/core";
import { selectContainerState } from "redux/container";
import { useAppSelector } from "redux/store";
import { Suchfeld } from "types/query/Suchfeld";
import CheckboxGroup from "./CheckboxGroup";
import ChipPopover from "./ChipPopover";
import Radios from "./Radios";

type Props = {
  open: boolean;
  anchorEl: HTMLDivElement;
  handleClose: () => void;
  onChange: (value: string, key: string) => void;
  onToggle: (value: string) => void;
  suchFeld: Suchfeld;
};

export default function SuchePopover(props: Props) {
  const { open, anchorEl, handleClose, onChange, onToggle, suchFeld } = props;
  const classes = useStyle();
  const containerState = useAppSelector(selectContainerState);
  return (
    <ChipPopover open={open} anchorEL={anchorEl} handleClose={handleClose}>
      <Paper elevation={1} className={classes.container}>
        <div
          className={classes.spalte}
          style={{ borderRight: "1px solid lightGray" }}
        >
          <Radios
            elements={containerState.bedingungen}
            selectedValue={suchFeld.bedingung}
            onChange={(v) => onChange(v, "bedingung")}
            header={"Bedingung"}
          />

          <Radios
            elements={containerState.modifiers}
            selectedValue={suchFeld.modifier}
            onChange={(v) => onChange(v, "modifier")}
            header={"Modifiers"}
          />
        </div>
        <div className={classes.spalte}>
          <CheckboxGroup
            elements={containerState.suchQueryFelder}
            selectedValues={suchFeld.feld}
            onChange={(v) => onToggle(v[0])}
            header={"Suchfeld"}
          />
        </div>
      </Paper>
    </ChipPopover>
  );
}

const useStyle = makeStyles({
  container: {
    minWidth: 400,
    padding: 5,
    display: "flex",
    flexDirection: "row",
  },
  spalte: {
    padding: 5,
    flex: 1,
  },
});
