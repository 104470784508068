import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import meldungenReducer, { MeldungenStateType } from "./meldungen";
import chartReducer from "./chart";
import exportListeReducer from "./exportListe";
import filterReducer from "./filter";
import containerReducer, { ContainerStateType } from "./container";
import toastReducer from "./toast";
import meldungNavigationReducer from "./meldungNavigation";
import { env } from "process";

export const store = configureStore({
  reducer: {
    meldungen: meldungenReducer,
    filter: filterReducer,
    meldungNavigation: meldungNavigationReducer,
    container: containerReducer,
    toast: toastReducer,
    exportListe: exportListeReducer,
    chart: chartReducer,
  },
  devTools: env.NODE_ENV !== "production",
});

export interface IState {
  readonly meldungen: MeldungenStateType;
  readonly container: ContainerStateType;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
