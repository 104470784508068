import { t, Trans } from "@lingui/macro";
import {
  Paper,
  makeStyles,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DetailseiteInfoSkelton from "components/DetailseiteInfo/DetailseiteInfoSkelton";
import HashtagsSkelton from "components/DetailseiteInfo/HashtagsSkelton";
import { MediasSkelton } from "components/Medias/MediasSkelton";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  resetNavigationIndex,
  selectMeldungNavigationState,
} from "redux/meldungNavigation";
import { selectMeldungenState } from "redux/meldungen";
import { getMeldungenUrl } from "lib/urls";
import { useHistory } from "react-router-dom";

export default function MeldungsDetailSkeleton() {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const meldungenState = useAppSelector(selectMeldungenState);
  const meldungNavigationState = useAppSelector(selectMeldungNavigationState);

  const zurueck = () => {
    dispatch(resetNavigationIndex);
    history.push(getMeldungenUrl());
  };

  return (
    <Paper elevation={1} className={classes.paper}>
      <div className={classes.paperHeader}>
        <Button className={classes.button} onClick={zurueck}>
          <ArrowBackIosIcon fontSize="small" />
          <Trans id="begriff.zurueck">Zurück</Trans>
        </Button>
        <div className={classes.navigatorContainer}>
          <IconButton disabled>
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
          <Trans id="begriff.meldungVonPrefix">Meldung</Trans>
          {` ${meldungNavigationState.currentIndex + 1} `}{" "}
          <Trans id="begriff.meldungVon"> von </Trans>
          {` ${meldungenState.count === 0 ? 1 : meldungenState.count}`}
          <IconButton disabled>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </div>
        <div className={classes.actionsContainer}></div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <Skeleton variant="text" width={100} />
          <Typography variant="h5" display="block" className={classes.headline}>
            <Skeleton variant="text" />
          </Typography>

          <MediasSkelton />
          <div className={classes.seperator}></div>
          <Skeleton
            variant="rect"
            height={300}
            className={classes.meldungHTML}
          />
        </div>
        <div className={classes.detailContainer}>
          <DetailseiteInfoSkelton
            count={9}
            headline={t({
              id: "begriff.meldungsInformationen",
              message: "Meldungsinformationen",
            })}
          />
          <div className={classes.seperator}></div>
          <DetailseiteInfoSkelton
            count={6}
            headline={t({
              id: "begriff.weitereInformationen",
              message: "Weitere Informationen",
            })}
          />
          <div className={classes.seperator}></div>
          <DetailseiteInfoSkelton
            count={4}
            headline={t({
              id: "begriff.engagement",
              message: "Engagement",
            })}
          />
          <div className={classes.seperator}></div>
          <HashtagsSkelton
            count={6}
            headline={t({
              id: "begriff.tags",
              message: "tags",
            })}
          />
        </div>
      </div>
    </Paper>
  );
}

const useStyle = makeStyles((theme) => ({
  images: {
    borderRadius: 10,
    display: "block",
    justifyContent: "center",
    maxWidth: 500,
    maxHeight: 550,
  },

  paper: {
    minHeight: "90vh",
    borderRadius: 10,
    width: 1300,
  },
  paperHeader: {
    margin: 10,
    borderBottom: "1px solid lightGray",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    fontWeight: "bold",
    alignItems: "center",
  },
  navigatorContainer: {
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
  },
  actionsContainer: {
    minWidth: "10%",
  },
  seperator: {
    marginTop: 20,
  },
  contentContainer: {
    paddingRight: 10,
    borderRight: "1px solid lightGray",
  },
  detailContainer: {
    paddingRight: 20,
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "65% 35%",
    gap: 20,
    padding: 20,
  },
  headline: {
    fontWeight: "bolder",
    "& .mark": theme.textHighlight,
  },
  meldungHTML: {
    "& .mark": theme.textHighlight,
  },
}));
