import { Button, makeStyles, Paper } from "@material-ui/core";
import { useState } from "react";
import { MediaAttachment } from "types/MediaAttachment";
import MediaCard from "./MediaCard";

type Props = {
  images: MediaAttachment[];
};

function Images(props: Props) {
  const [index, setIndex] = useState<number>(0);
  const classes = useStyle();

  if (props.images?.length === 0) return null;
  return (
    <Paper className={classes.images} elevation={1}>
      <MediaCard image={props?.images[index]} />
      {props.images?.length > 1 && (
        <div className={classes.navigationButtonContainer}>
          {props.images.map((_, i) => (
            <Button
              key={_.mediaUrl}
              className={classes.navigationButton}
              onClick={() => setIndex(() => i)}
              variant={"contained"}
              color={i === index ? "primary" : "default"}
            />
          ))}
        </div>
      )}
    </Paper>
  );
}

const useStyle = makeStyles({
  images: {
    borderRadius: 10,
    display: "block",
    justifyContent: "center",
    maxWidth: 500,
  },
  navigationButtonContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexFlow: "wrap",
    paddingTop: 5,
    paddingBottom: 5,
  },
  navigationButton: {
    borderRadius: 500,
    margin: 2,
    height: 15,
    width: 15,
    minWidth: 0,
    padding: 0,
  },
});

export default Images;
