import LaenderChip from "./Chips/LaenderChip";
import MedienartenChip from "./Chips/MedienartenChip";
import SprachenChip from "./Chips/SprachenChip";
import ZeitChip from "./Chips/ZeitChip";

export default function FilterChips() {
  return (
    <>
      <ZeitChip />
      {/* //Divider Chip hinzufügen, also | ein senkrechter Strich nachunten */}
      <SprachenChip />
      <LaenderChip />
      <MedienartenChip />
    </>
  );
}
