import { FormLabel, makeStyles, Paper } from "@material-ui/core";
import { selectContainerState } from "redux/container";
import { changeFilter, selectFilterState } from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import { primaryColor } from "theme";
import CheckboxGroup from "./Core/CheckboxGroup";
import FilterChip from "./FilterChip";
import { getArrayAfterToggle } from "./Libs/shared";
import { t } from "@lingui/macro";


export default function SprachenChip() {
  const filter = useAppSelector(selectFilterState);
  const container = useAppSelector(selectContainerState);
  const dispatch = useAppDispatch();
  const classes = useStyle();

  const handleMedienartChanged = (value: string[], gattung: string) => {
    const medienartenForGattung = container.medienarten
      .filter((m) => m.gattung === gattung)
      .map((m) => m.bezeichner);

    const medienarten = getArrayAfterToggle(
      value,
      filter.medienarten,
      medienartenForGattung.length
    );
    dispatch(
      changeFilter({
        ...filter,
        medienarten: medienarten,
      })
    );
  };

  const onSelectSpecific = (elements: string[], key: string) => {
    dispatch(
      changeFilter({
        ...filter,
        [key]: elements,
      })
    );
  };

  const onSelectAllMedienarten = (gattung: string) => {
    const medienartenForGattung = container.medienarten
      .filter((m) => m.gattung === gattung)
      .map((m) => m.bezeichner);

    const isAllSelected = medienartenForGattung.every((m) =>
      filter.medienarten.includes(m)
    );
    if (!isAllSelected)
      dispatch(
        changeFilter({
          ...filter,
          medienarten: [...filter.medienarten, ...medienartenForGattung],
        })
      );
    else {
      dispatch(
        changeFilter({
          ...filter,
          medienarten: [
            ...filter.medienarten.filter(
              (f) => !medienartenForGattung.includes(f)
            ),
          ],
        })
      );
    }
  };

  const medienarten: Leaf[] = container.medienarten.reduce((acc, current) => {
    if (!acc.find((a) => a.header === current.gattung)) {
      acc.push({
        header: current.gattung,
        options: [{ bezeichner: current.bezeichner, name: current.name }],
      });
    } else {
      const found = acc.find((a) => a.header === current.gattung);
      found.options.push({
        bezeichner: current.bezeichner,
        name: current.name,
      });
    }
    return acc;
  }, []);

  return (
    <FilterChip
      xButtonBehaviour={() =>
        dispatch(
          changeFilter({
            ...filter,
            medienarten: container.medienarten.map((m) => m.bezeichner),
          })
        )
      }
      TitleComponent={Title}
      titleProps={{
        titleMedienart: "Medienart",
        titleMedienarten: t({ 
          id: "medienartChip.medienarten", 
          message: "Medienarten", 
        }), 

        total: container.medienarten.length,
        limit: 2,
        elements: container.medienarten
          .filter((s) => filter.medienarten?.includes(s.bezeichner))
          .map((s) => s.name),
      }}
      PopoverContentComponent={Popover}
      popoverProps={{
        paperClassName: classes.paper,
        header: t({ 
          id: "medienartChip.medienarten", 
          message: "Medienarten", 
        }), 
        onChange: handleMedienartChanged,
        onSelectAll: (gattung: string) => onSelectAllMedienarten(gattung),
        onSelectSpecific: (values: string[]) =>
          onSelectSpecific(values, "medienarten"),
        tree: medienarten,
        selectedValues: filter.medienarten ?? [],
      }}
    />
  );
}

const useStyle = makeStyles({
  paper: {
    minWidth: 300,
    borderRadius: 10,
    padding: "10px 15px",
  },
  header: {
    fontWeight: "bolder",
    marginBottom: 5,
    marginTop: 5,
    cursor: "pointer",
    "&:hover": {
      color: primaryColor,
    },
  },
});

type TitleProps = {
  titleMedienart: string;
  titleMedienarten: string;
  elements: string[];
  total: number;
  limit: number;
};

function Title(props: TitleProps) {
  const { elements, titleMedienart, titleMedienarten, limit, total } = props;

  if (!elements || elements.length === 0) return <>{"k.A."}</>;

  if (elements?.length > limit)
    return (
      <>
        {elements?.length + "/" + total} {titleMedienarten}
      </>
    );

  if (elements?.length === 1)
    return (
      <>
        {titleMedienart}: {elements?.join(", ")}
      </>
    );

  return (
    <>
      {titleMedienarten}: {elements?.join(", ")}
    </>
  );
}

type Leaf = {
  header: string;
  key: string;
  options: KeyValuePair[];
};

type KeyValuePair = {
  bezeichner: string;
  name: string;
};

type PopoverProps = {
  header: string;
  selectedValues: string[];
  tree: Leaf[];
  onChange: (values: string[], gattung: string) => void;
  paperClassName: string;
  onSelectAll: (gattung: string) => void;
  onSelectSpecific: (medienart: string[]) => void;
};

function Popover(props: PopoverProps) {
  //TODO: muss überarbeitet werden, das ist nicht gut das ich zwei unterschiedliche header habe, die medienarten sollten wie die sprachen auch ein Header haben
  const classes = useStyle();

  return (
    <Paper className={props.paperClassName} elevation={1}>
      <FormLabel component="legend" className={classes.header}>
        {props.header}
      </FormLabel>
      {props.tree.map((t) => (
        <CheckboxGroup
          header={t.header}
          selectedValues={props.selectedValues}
          elements={t.options}
          onChange={(value: string[]) => props.onChange(value, t.header)}
          onSelectAll={() => props.onSelectAll(t.header)}
          onSelectSpecific={(medienarten: string[]) =>
            props.onSelectSpecific(medienarten)
          }
          expandable
        ></CheckboxGroup>
      ))}
    </Paper>
  );
}
