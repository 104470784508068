import React from "react";

export default function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 45">
      <g style={{ fill: "rgb(62, 62, 62)" }}>
        <polygon points="0 0.17 0 38.33 13.94 38.33 13.94 33.16 5.14 33.16 5.14 5.17 23.73 5.17 23.73 33.99 13.94 38.21 13.94 44.84 28.71 38.3 28.71 0.17 0 0.17"></polygon>
        <path d="M83.19,5.18h2.94v7.41a11.94,11.94,0,0,0,.12,2.19A1.54,1.54,0,0,0,87.8,16a1.36,1.36,0,0,0,.92-.35,1.44,1.44,0,0,0,.47-.93,18.6,18.6,0,0,0,.05-2.16V5.18H92v7.3a15.59,15.59,0,0,1-.25,3.26,3.2,3.2,0,0,1-1.27,1.84,4.59,4.59,0,0,1-2.82.77A5.22,5.22,0,0,1,85,17.78a3.19,3.19,0,0,1-1.4-1.59,10.78,10.78,0,0,1-.43-3.61Z"></path>
        <polygon points="32.09 5.17 32.09 18.26 39.23 18.26 39.23 15.59 35.09 15.59 35.09 5.17 32.09 5.17"></polygon>
        <path d="M47,5.17h-3L40.18,18.26h3l.63-2.67h3.35l.64,2.67h2.95Zm-2.73,8.11,1.27-5.37,1.32,5.37Z"></path>
        <path d="M78.9,5.17H76L72.06,18.26h3l.63-2.67H79l.64,2.67H82.6Zm-2.73,8.11,1.27-5.37,1.32,5.37Z"></path>
        <path d="M78.9,20.08H76L72.06,33.16h3l.63-2.67H79l.64,2.67H82.6Zm-2.73,8.11,1.27-5.37,1.32,5.37Z"></path>
        <polygon points="51.8 5.18 54.9 5.18 58.24 13.3 58.24 5.18 60.57 5.18 60.57 18.26 57.92 18.26 54.09 9.17 54.09 18.26 51.8 18.26 51.8 5.18"></polygon>
        <path d="M66.8,5.17H62.63V18.26h4.15c1.84,0,5.1-.39,5.1-6.71C71.89,7.93,70.31,5.17,66.8,5.17ZM65.45,15.61V7.44c1.84,0,3.55.37,3.55,4.2C69,15.75,67.79,15.61,65.45,15.61Z"></path>
        <path d="M60.51,20.07H56.34V33.16h4.15c1.84,0,5.1-.39,5.1-6.71C65.59,22.83,64,20.07,60.51,20.07ZM59.16,30.51V22.35c1.84,0,3.55.37,3.55,4.2C62.71,30.65,61.5,30.51,59.16,30.51Z"></path>
        <polygon points="32.1 33.16 32.1 20.11 36.32 20.11 38.15 28.18 40.09 20.11 44.36 20.11 44.36 33.16 41.7 33.16 41.7 22.27 39.23 33.16 37.16 33.16 34.65 22.27 34.65 33.16 32.1 33.16"></polygon>
        <polygon points="46.45 20.1 46.45 33.16 54.5 33.16 54.5 30.85 49.53 30.85 49.53 27.71 53.5 27.71 53.5 25.36 49.53 25.36 49.53 22.43 54.5 22.43 54.5 20.1 46.45 20.1"></polygon>
        <rect x="67.5" y="20.1" width="2.99" height="13.06"></rect>
      </g>
    </svg>
  );
}
