import { gql } from "@apollo/client";
import { Query } from "types/Query";
import { gqlClient } from "./client";
import { SEARCH_QUERY_SEARCHID } from "./queries";

export async function getSearchQueryGQL(searchId: string): Promise<Query> {
  const result = await gqlClient.query({
    query: gql(SEARCH_QUERY_SEARCHID),
    variables: {
      searchId: searchId,
    },
  });
  return result.data.resolveSearchQuery;
}
