import { LinearProgress, makeStyles } from "@material-ui/core";

type MeldungsLoadingProps = {
  show: boolean;
};

export default function MeldungsLoading(props: MeldungsLoadingProps) {
  const classes = useStyles();

  return props.show ? (
    <LinearProgress className={classes.root} />
  ) : (
    <div className={classes.root}></div>
  );
}

const useStyles = makeStyles({
  root: {
    height: 4,
  },
});
