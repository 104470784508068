import de from "./de/messages";
import de_CH from "./de-CH/messages";
import en from "./en/messages";
import fr from "./fr/messages";
import { LocaleTag } from "types/I18n";

export default function getCatalogueByLocaleTag(localeTag: LocaleTag) {
  switch (localeTag) {
    case "de-DE":
      return de;
    case "de-CH":
      return de_CH;
    case "en-GB":
      return en;
    case "en-US":
      return en;
    case "fr":
      return fr;
    default:
      return de;
  }
}

export const Catalogues = { de, de_CH, en, fr };
