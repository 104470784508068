import { IconButton } from "@material-ui/core";
import ChartIcon from "icons/ChartIcon";

type Props = {
  selected: boolean;
  onSelect: () => void;
  disabled?: boolean;
};

export default function ChartButton(props: Props) {
  return (
    <IconButton onClick={props.onSelect} disabled={props.disabled}>
      <ChartIcon selected={props.selected} />
    </IconButton>
  );
}
