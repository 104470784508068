import { createTheme } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

// spezifische Informationen in den Themes ablegen
declare module "@material-ui/core/styles" {
  interface Theme {
    textHighlight: {
      backgroundColor: string;
      borderRadius: string;
      color: string;
      padding: string;
    };
    textColor: string;
    textHeadlineColor: string;
  }
  interface ThemeOptions {
    textHighlight?: {
      backgroundColor?: string;
      color?: string;
      borderRadius?: string;
      padding?: string;
    };
    textColor: string;
    textHeadlineColor: string;
  }
}

export const primaryColor = "#00C1BE";
export const primaryHoverColor = "#00adaa";

export const secondaryColor = "white";
export const grayColor = "#3E3E3E";

export const disabledHoverColor = "#B5B5B5";
export const disabledColor = "#D3D3D3";

const textColor = "#7A7A7A";
const textHeadlineColor = "#000000";

const theme = createTheme({
  textHighlight: {
    backgroundColor: alpha(primaryColor, 0.2),
    borderRadius: "5px",
    padding: "2px 0px 2px 0px",
    color: "black",
  },
  textColor: textColor,
  textHeadlineColor: textHeadlineColor,
  palette: {
    primary: {
      light: primaryColor,
      main: primaryColor,
      dark: primaryColor,
      contrastText: primaryColor,
    },
  },
  overrides: {
    MuiPopover:{
      paper:{
        borderRadius:10
      }
    },
    MuiFormLabel:{
      root:{
        color:"#555555"
      }
    },
    MuiInput: {
      formControl: {
        fontSize: 12,
      },
    },
    MuiChip: {
      deleteIcon: {
        color: "white",
      },
    },
    MuiCheckbox: {
      root: {
        color: primaryColor,
      },
    },
    MuiPaper: {
      root: {
        color: textColor,
      },
    },
  },
  typography: {
    fontFamily: ["Roboto Condensed"].join(","),
    body1: {
      fontSize: 12,
    },
  },
});

export default theme;
