import React from "react";

type Props = {
  selected: boolean;
};

export default function KachelnIcon(props: Props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height="20"
      width="20"
      aria-hidden="true"
      focusable="false"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        overflow: "hidden",
        opacity: props.selected ? 1 : 0.5,
      }}
    >
      <g data-name="Layer 2">
        <g data-name="grid">
          <path d="M9 3H5a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2V5a2 2 0 00-2-2zM5 9V5h4v4zM19 3h-4a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2V5a2 2 0 00-2-2zm-4 6V5h4v4zM9 13H5a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2v-4a2 2 0 00-2-2zm-4 6v-4h4v4zM19 13h-4a2 2 0 00-2 2v4a2 2 0 002 2h4a2 2 0 002-2v-4a2 2 0 00-2-2zm-4 6v-4h4v4z"></path>
        </g>
      </g>
    </svg>
  );
}
