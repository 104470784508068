export const GET_ZEIT_AGGREGATION = `query get($batchAggregationArgs: BatchAggregationInput, $localeTag: String){
    batchAggregation(batchAggregationArgs: $batchAggregationArgs, localeTag: $localeTag){
        zeit {
                datenpunkte {
                    datum
                    anzahlMeldungen
                    reichweiteGesamt
                }
            } 
        titel {
                datenpunkt {
                    bezeichnerWert
                    titelName
                    wertAggregation
                }
            }   
    }
}`;
