import { t } from "@lingui/macro";

export default function TranslateLaender() {
  return [
    {
      name: t({
        id: "laenderChip.de",
        message: "Deutschland",
      }),
      bezeichner: "de",
    },
    {
      name: t({
        id: "laenderChip.at",
        message: "Österreich",
      }),
      bezeichner: "at",
    },
    {
      name: t({
        id: "laenderChip.li",
        message: "Liechtenstein",
      }),
      bezeichner: "li",
    },
    {
      name: t({
        id: "laenderChip.ch",
        message: "Schweiz",
      }),
      bezeichner: "ch",
    },
  ];
}
