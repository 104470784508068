import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { primaryColor } from "theme";
import ChipPopover from "./Core/ChipPopover";
import RefreshIcon from "icons/RefreshIcon";

type FilterChipProps<TPopoverProps, TTitleProps> = {
  titleProps: TTitleProps;
  popoverProps: TPopoverProps;
  removeChip?: boolean;
  PopoverContentComponent: (popoverProps: TPopoverProps) => JSX.Element;
  TitleComponent: (titleProps: TTitleProps) => JSX.Element;
  xButtonBehaviour: () => void;
};

export default function FilterChip<TPopoverProps, TTitleProps>(
  props: FilterChipProps<TPopoverProps, TTitleProps>
) {
  const { PopoverContentComponent, TitleComponent } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);

  const onElementClick = (e) => {
    setAnchorEl(() => e.target);
  };
  const classes = useFilterStyle();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.prefix} onClick={onElementClick}>
          <TitleComponent {...props.titleProps} />
        </div>
        <div className={classes.x} onClick={props.xButtonBehaviour}>
          <RefreshIcon />
        </div>
      </div>
      <ChipPopover
        open={Boolean(anchorEl)}
        handleClose={() => setAnchorEl(null)}
        anchorEL={anchorEl}
      >
        <PopoverContentComponent {...props.popoverProps} />
      </ChipPopover>
    </>
  );
}

const useFilterStyle = makeStyles({
  container: {
    backgroundColor: primaryColor,
    color: "white",
    height: "100%",
    display: "inline-flex",
    borderRadius: 5,
    marginRight: 5,
    marginTop: 2,
    marginBottom: 2,
    borderCollapse: "separate",
    overflow: "hidden",
    cursor: "pointer",
    alignItems: "center",
  },
  prefix: {
    alignItems: "center",
    padding: 7,
  },
  x: {
    backgroundColor: primaryColor,
    marginRight: 5,
    alignItems: "center",
    height: "100%",
    display: "grid",
    "& > svg": {
      fontSize: 15,
    },
    justifyContent: "center",
    "&:hover": {
      color: "red",
    },
  },
  paper: {
    padding: 5,
    minWidth: 200,
  },
});
