import moment from "moment";
import { LocaleTag } from "types/I18n";

export const getDateFormat = (localeTag: LocaleTag) => {
    switch (localeTag) {
      case "de-DE":
      case "de-CH":
        return "DD.MM.YYYY";
      case "en-US":
        return "MM/DD/YYYY";
      case "fr":
        return "DD/MM/YYYY";
      case "en-GB":
        return "DD/MM/YYYY";

      default:
        return "DD.MM.YYYY";
    }
  };

export const getDateWithoutOffset = (date: Date) => {
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - offset);
}

export const parseDateFromLocale = (date: string, localeTag: LocaleTag) => {
    return moment(
        date,
        getDateFormat(localeTag),
        localeTag.slice(0, 1) // nur ersten 2 buchstaben von locale nehmen (de-DE -> de)
      ).toDate();
    }