import { useEffect, useState } from "react";
import { getAuthentificationResult } from "requests/meldungenRequests";
import { SESSION_LOCAL_STORAGE_KEY } from "types/constants";

export type UserType = {
  sessionId: string;
  isAuthenticated: boolean;
};

export default function useAuthentication(sessionId: string) {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    if (sessionId && sessionId !== null)
      localStorage.setItem(SESSION_LOCAL_STORAGE_KEY, sessionId);
    getAuthentificationResult()
      .then((r) => {
        if (r && r.istAuthentifiziert) {
          setUser({
            sessionId: r.sessionId,
            isAuthenticated: r.istAuthentifiziert,
          });
          //wenn der nutzer authentifiziert ist dann wollen wir ja den session mitspeichern
          localStorage.setItem(SESSION_LOCAL_STORAGE_KEY, r.sessionId);
        } else {
          localStorage.removeItem(SESSION_LOCAL_STORAGE_KEY);
        }
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  }, [sessionId]);

  return { loading, user };
}
