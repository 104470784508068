import { Drawer } from "@material-ui/core";

type Props = {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
  position: "right" | "left" | "bottom" | "top";
};

export default function FloatingPanel(props: Props) {
  return (
    <Drawer
      anchor={props.position}
      open={props.open}
      onClose={() => props.onClose()}
    >
      {props.children}
    </Drawer>
  );
}
