import Flag from "react-world-flags";

export function getArrayAfterToggle(
  newElements: string[],
  existingElements: string[],
  gesamt: number
): string[] {
  //wenn wir kommen mit der gesamte array und alles ist an dann alles ausschalten
  if (
    existingElements &&
    existingElements.length === gesamt &&
    newElements.length === gesamt
  )
    return [];

  if (!existingElements || newElements.length === gesamt) return newElements;

  const alreadyExistingValues = existingElements.filter((a) =>
    newElements.includes(a)
  );

  if (alreadyExistingValues.length === 0)
    return [...newElements, ...existingElements];

  return existingElements.filter((a) => !alreadyExistingValues.includes(a));
}

export function getChipTitleFromElements(
  titleSingle: string,
  titleMany: string,
  elements: string[],
  limit: number,
  total: number
): string {
  if (!elements || elements.length === 0) return "k.A.";

  if (elements?.length > limit)
    return `${elements?.length + " / " + total} ${titleMany}`;

  if (elements?.length === 1) return `${titleSingle}: ${elements?.join(", ")}`;

  return `${titleMany}: ${elements?.join(", ")}`;
}

export function getLocalisationChip(
  title: string,
  elements: string[]
): JSX.Element {
  return (
    <>
      {title}:{" "}
      {elements?.length === 0
        ? "k.A."
        : elements.map((e) => (
            <Flag
              style={{ margin: "0px 1px", height: "9px", width: "15px" }}
              // width={15}
              code={e.toLowerCase()}
              fallback={<span>{e.toLowerCase()}</span>}
            />
          ))}
    </>
  );
}
