import { makeStyles, Paper } from "@material-ui/core";
import { getDateWithoutOffset, parseDateFromLocale } from "lib/DateHelpers";
import { LocaleContext } from "locales";
import React, { useContext } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { selectContainerState } from "redux/container";
import { changeFilter, selectFilterState } from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import { format } from "numerable";
import { de } from "numerable/locale";
import {
  DatenpunkteTitel,
  DatenpunkteZeit,
} from "requests/aggregationRequests";

type ChartState = {
  refAreaLeft: string;
  refAreaRight: string;
};

const initialState: ChartState = {
  refAreaLeft: "",
  refAreaRight: "",
};

type Props = {
  title: string;
  color: string;
  data: (DatenpunkteZeit | DatenpunkteTitel)[];
  dataTitelBool: boolean;
  xAxisDataKey: string;
  yAxisDataKey: string;
  dataDescription: string;
};

const CustomTooltip = ({ active, payload, label, description }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.tooltip}>
        {description}: {payload[0].value.toLocaleString()}
      </div>
    );
  }

  return null;
};

export default function Zeitchart(props: Props) {
  const [state, setState] = React.useState<ChartState>(initialState);
  const filterState = useAppSelector(selectFilterState);
  const containerState = useAppSelector(selectContainerState);
  const dispatch = useAppDispatch();
  const i18n = useContext(LocaleContext);

  const classes = useStyles();

  const formatZeit = (item: any) => item.toLocaleString();
  const formatValue = (item: any) => format(item, "0,000.## a", { locale: de });

  const berechneInterval = (datapoints: number) => {
    return Math.floor(Math.max(0, datapoints / 10 - 1));
  };

  const onSelect = () => {
    if (props.dataTitelBool === false) {
      let { refAreaLeft, refAreaRight } = state;
      if (
        (refAreaLeft === "" && refAreaRight === "") ||
        refAreaLeft === undefined ||
        refAreaRight === undefined
      ) {
        return;
      }
      if (
        refAreaLeft === refAreaRight ||
        (refAreaLeft !== "" && refAreaRight === "")
      ) {
        const datum = parseDateFromLocale(refAreaLeft, i18n.localeContext);

        dispatch(
          changeFilter({
            ...filterState,
            zeitpunktVon: getDateWithoutOffset(datum).toISOString(),
            zeitpunktBis: getDateWithoutOffset(datum).toISOString(),
            zeitangabe: containerState.zeitangaben,
            zeitraumGrundlage: filterState.zeitraumGrundlage,
          })
        );
        return;
      }
      let date1 = parseDateFromLocale(refAreaLeft, i18n.localeContext);
      let date2 = parseDateFromLocale(refAreaRight, i18n.localeContext);
      let temp: Date = new Date();
      if (date1.getTime() > date2.getTime()) {
        temp = date2;
        date2 = date1;
        date1 = temp;
      }
      dispatch(
        changeFilter({
          ...filterState,
          zeitpunktVon: getDateWithoutOffset(date1).toISOString(),
          zeitpunktBis: getDateWithoutOffset(date2).toISOString(),
          zeitangabe: containerState.zeitangaben,
          zeitraumGrundlage: filterState.zeitraumGrundlage,
        })
      );
    }
  };

  return (
    <Paper elevation={2} className={classes.mainContainer}>
      <div className={classes.header}>{props.title}</div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={1000}
          height={1000}
          data={props.data}
          onMouseLeave={() =>
            setState(() => ({ refAreaLeft: "", refAreaRight: "" }))
          }
          onMouseDown={(e) =>
            e && setState((prev) => ({ ...prev, refAreaLeft: e.activeLabel }))
          }
          onMouseMove={(e) =>
            e &&
            state.refAreaLeft &&
            setState((prev) => ({ ...prev, refAreaRight: e.activeLabel }))
          }
          // eslint-disable-next-line react/jsx-no-bind
          onMouseUp={onSelect}
        >
          <XAxis
            allowDataOverflow
            dataKey={props.xAxisDataKey}
            domain={["dataMin", "dataMax"]}
            type="category"
            tick={{ fill: "#333", fontSize: ".75rem" }}
            tickFormatter={formatZeit}
            interval={berechneInterval(props.data.length)}
          />
          <YAxis
            orientation="left"
            allowDataOverflow
            domain={["auto", "auto"]}
            type="number"
            yAxisId="1"
            tick={{ fill: "#333", fontSize: ".75rem" }}
            tickFormatter={formatValue}
            width={50}
          />
          <Tooltip
            content={<CustomTooltip description={props.dataDescription} />}
          />
          <Bar
            yAxisId="1"
            type="natural"
            dataKey={props.yAxisDataKey}
            fill={props.color}
            animationDuration={300}
          />
          {state.refAreaLeft && state.refAreaRight ? (
            <ReferenceArea
              yAxisId="1"
              x1={state.refAreaLeft}
              x2={state.refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
}

const useStyles = makeStyles({
  mainContainer: {
    borderRadius: "10px",
    padding: 20,
    paddingTop: 30,
  },

  header: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: "16px",
    marginTop: "0px",
    color: "#4d4d4d",
  },

  tooltip: {
    background: "white",
    padding: "5px",
  },

  label: {
    background: "white",
  },
});
