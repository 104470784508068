import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  deleteMeldungGQL, getMeldungenByIdsGQL } from "requests/meldungenRequests";
import { RootState } from "./store";
import { MeldungView } from "types/MeldungView";

type ExportListeStateType = {
    status: "loading" | "idle" | "failed";
    items: MeldungView[];
    error: string | null;
}

const initialState: ExportListeStateType = {
    status: "idle",
    items: [],
    error: null
};

type ExportListePayloadType = {
    meldungen: MeldungView[];
    nextPage: boolean;
}

export const getExportListeMeldungen = createAsyncThunk(
  "exportListe/getExportListe",
  async (props: {ids: string[], nextPage: boolean}):Promise<ExportListePayloadType> => {
    const meldungen= await getMeldungenByIdsGQL(props.ids);
    return {meldungen, nextPage: props.nextPage};
  }
);


export const deleteMeldung = createAsyncThunk(
  "meldungen/deleteMeldung",
  async (id:string) => {
    const data = await deleteMeldungGQL(id);
    if(data) return data;
    else return null;
  },
  {}
  );

export const exportListeSlice = createSlice({
  name: "exportListe",
  initialState: initialState,
  reducers: {
    removeFromExportListe: (state, action: PayloadAction<string>) => {
        state.items = state.items.filter(item => item.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExportListeMeldungen.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getExportListeMeldungen.fulfilled,
        (state, action: PayloadAction<ExportListePayloadType>) => {
          if(action.payload.nextPage) {
            state.items = [...state.items, ...action.payload.meldungen];
          }else {
            state.items = action.payload.meldungen;
          }
          state.status= "idle";
        }
      )
      .addCase(getExportListeMeldungen.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteMeldung.pending , (state,action) => {
        const id  =  action.meta.arg;
        state.items = state.items.filter(item => item.meldungDetailUrl !== id);
      })
  },
});

export const {
    removeFromExportListe
} = exportListeSlice.actions;

export const selectExportListeState = (state: RootState) => state.exportListe;

export default exportListeSlice.reducer;
