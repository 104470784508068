import { makeStyles, Paper, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

type Props = {};

export default function KachelSkelton(props: Props) {
  const classes = useStyles();

  return (
    <Paper className={classes.paperStyle} elevation={3}>
      <Grid className={classes.grid} container alignItems={"flex-start"}>
        <Grid item xs={8} className={classes.kachel}>
          <div className={classes.kachelHeaderContainer}>
            <div className={classes.phSelekt}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={30}
                height={30}
              />
            </div>
            <div className={classes.kachelInformationContainer}>
              <Skeleton variant="text" animation="wave" height={15} />
              <Skeleton variant="text" animation="wave" height={15} />
            </div>
          </div>
          <div>
            <Skeleton variant="text" animation="wave" height={30} />
            <Skeleton variant="rect" animation="wave" height={120} />
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className={classes.phImage}>
            <Skeleton
              variant="rect"
              animation="wave"
              width={120}
              height={150}
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  phSelekt: {
    padding: 3,
  },

  phImage: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingRight: 10,
  },

  paperStyle: {
    minWidth: "20em",
    height: 250,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    boxShadow:
      "rgba(9, 32, 77, 0.12) 0px 3px 8px, rgba(29, 17, 51, 0.12) 0px 0px 2px",
    borderRight: "",
  },
  kachelHeaderContainer: {
    display: "flex",
  },
  kachelInformationContainer: {
    color: "gray",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  grid: {
    flexGrow: 1,
  },
  kachel: {
    borderRight: "1px solid #dadada",
    flexGrow: 1,
    height: 220, //TODO: Nicht fixed,
    paddingRight: 6,
    marginTop: 0,
  },
}));
