import { makeStyles, Tooltip } from "@material-ui/core";
import { MediaAttachment } from "types/MediaAttachment";

type Props = {
  image: MediaAttachment;
};

function MediaCard(props: Props) {
  const { image } = props;
  const classes = useStyle();
  const openLink = () =>
    window.open(image.mediaLink ?? image.mediaUrl, "_blank").focus();
  if (!image) return null;
  return (
    <div className={classes.container}>
      {image?.description && image.description !== "" ? (
        <Tooltip title={image.description} placement={"right"} arrow>
          <img
            onClick={openLink}
            className={classes.image}
            src={image.mediaUrl}
            alt={image.description}
          />
        </Tooltip>
      ) : (
        <img
          onClick={openLink}
          className={classes.image}
          src={image.mediaUrl}
          alt={image.description}
        />
      )}
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    height: 500,
  },
  image: {
    borderRadius: 10,
    backgroundColor: "black",
    objectFit: "scale-down",
    cursor: "pointer",
    height: 500,
  },
  description: {
    textAlign: "center",
    fontSize: 10,
  },
});

export default MediaCard;
