type Props = {
  selected: boolean;
};

export default function ChartIcon(props: Props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height="20"
      width="20"
      aria-hidden="true"
      focusable="false"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        overflow: "hidden",
        opacity: props.selected ? 1 : 0.5,
      }}
    >
      <g data-name="Layer 2">
        <g data-name="grid">
          <path d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"></path>
          <path d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414L13 12.414l2.293 2.293z"></path>
        </g>
      </g>
    </svg>
  );
}
