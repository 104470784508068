import { makeStyles } from "@material-ui/styles";
import Kachel from "components/Kachel";
import KachelSkelton from "components/Kachel/KachelSkelton";
import { getDemoMeldung } from "./MeldungViewDemo";

export default function Playground() {
  const classes = useStyle();

  const meldung = getDemoMeldung();

  return (
    <div className={classes.root}>
      <div className={classes.grid}>
        {/* <MeldungsDetailSkeleton /> */}
        <KachelSkelton />
        <Kachel
          meldung={meldung}
          selected={false}
          exported={false}
          loadingToggle={true}
          key={1}
          query="11"
          onToggle={() => {}}
        />
      </div>
    </div>
  );
}

const useStyle = makeStyles({
  root: {
    padding: 10,
  },
  grid: {
    display: "grid",
    gap: 30,
    gridTemplateColumns: "repeat(auto-fill, minmax(30em, 1fr))",
    marginBottom: 10,
    height: "100%",
  },
});
