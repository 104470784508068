import { Provider } from "react-redux";
import { I18nContextProvider } from "./locales";
import { store } from "./redux/store";
import AppRoutes from "AppRoutes";
import { makeStyles } from "@material-ui/core";
import Footer from "components/Footer";

export default function App() {
  const classes = useStyles();

  return (
    <I18nContextProvider>
      <Provider store={store}>
        <div className={classes.mainContainer}>
          <AppRoutes />
        </div>
        <Footer />
      </Provider>
    </I18nContextProvider>
  );
}

const useStyles = makeStyles({
  mainContainer: {
    minHeight: "calc(100vh - 60px)",
    overflow: "hidden",
    paddingBottom: "10px",
  },
});
