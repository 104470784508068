import { LocaleTag } from "types/I18n";

export type NumberOptions = {
  /** Roundet die Zahl. {round=2} ist ein Shortcut für {round:true,maxDecimals:2} */
  round?: boolean | number;
  /** Wandelt alles was keine Zahl (inkl undefined und null) in 0 um */
  nonNumberToZero?: boolean;
  /** Kürzt die Darstellung der Zahl. 1000 -> 1k. nurwert verhindert das k mit ausgegeben wird. */
  kurz?: "mitsuffix" | "nurwert"; // Tsd etc
  /** Schneidet ab X decimals ab. */
  maxDecimals?: number;
  /** Gibts mindes X decimals aus. */
  minDecimals?: number;
  /** Der String wird ausgegeben wenn es null or undefined ist. z.B. k.A. */
  nullString?: string;
  /** Wie grob soll der rundung sein */
  factor?: number;
};

function handleNull(value: number | null | undefined, nullToZero?: boolean) {
  let newValue = value === undefined ? null : value;
  if (nullToZero === true && newValue === null) return 0;
  return newValue;
}

function round(value: number, decimals: number) {
  switch (decimals) {
    case 0:
      return Math.round(value);
    case 1: // standardfall um das pow zu sparen
      return Math.round(value * 10) / 10;
    case 2: // standardfall um das pow zu sparen
      return Math.round(value * 100) / 100;
    case 3: // standardfall um das pow zu sparen
      return Math.round(value * 1000) / 1000;
    default:
      const shift = Math.pow(10, decimals);
      return Math.round(value * shift) / shift;
  }
}

function prepareNumber(
  value: number | null | undefined,
  options: NumberOptions = {}
): [number | null, string] {
  let newValue = handleNull(value, options.nonNumberToZero);
  if (newValue === null) return [null, ""];

  let suffix = "";
  if (options.kurz) {
    if (newValue >= 1000000000) {
      newValue = newValue / 1000000000;
      if (options.kurz === "mitsuffix") suffix = ` Mrd`;
    } else if (newValue >= 1000000) {
      newValue = newValue / 1000000;
      if (options.kurz === "mitsuffix") suffix = ` Mio`;
    } else if (newValue >= 1000) {
      newValue = newValue / 1000;
      if (options.kurz === "mitsuffix") suffix = ` Tsd`;
    }
  }

  const roundDecimals =
    (options.kurz
      ? options.round === undefined
        ? 0
        : options.round
      : options.round) ?? false;
  if (roundDecimals !== false) {
    // es kann passieren das trotz rundung mehr als roundDecimals dargestellt werden,
    // daher setzen wir auch gleich maxDecimals um sicher zu gehen. (bei PDFCharts beobachtet)
    if (options.maxDecimals === undefined)
      options.maxDecimals = roundDecimals === true ? 0 : roundDecimals;
    newValue = round(newValue, roundDecimals === true ? 0 : roundDecimals);
  }

  // das wird von den Legenden des Grafikkarten benutzt, um ganze Zahlen zu zeigen
  // (bis "factor" wird nicht abgerundet).
  if (options.factor && newValue > options.factor)
    newValue = Math.round(newValue / options.factor) * options.factor;

  return [newValue, suffix];
}

export type FormatOptions = NumberOptions & {
  /** Soll der Tausendertrenner benutzt werden. Default:true */
  tausenderTrenner?: boolean;
};

// 1000.0000
export function formatNumber(
  locale: LocaleTag,
  value: number | null | undefined,
  options: FormatOptions = {}
): string | null {
  let [newValue, suffix] = prepareNumber(value, options);
  if (newValue === null) return options.nullString ?? null;

  return (
    newValue.toLocaleString(locale, {
      maximumFractionDigits: options.maxDecimals ?? 2,
      minimumFractionDigits: 0,
      useGrouping: options.tausenderTrenner ?? true,
    }) + suffix
  );
}

// ganze einfache nummer, ohne alles aber mit korrekten decimal trenner
export function formatNumberForInput(
  locale: LocaleTag,
  value: number | null,
  options: FormatOptions = {}
) {
  return formatNumber(locale, value, {
    round: false,
    maxDecimals: 6,
    tausenderTrenner: false,
    ...options,
  });
}

type MoneyOptions = NumberOptions & {
  waehrungsCode: string;
};

// 10.10 €
export function formatMoney(
  locale: LocaleTag,
  value: number | null,
  options: MoneyOptions
) {
  let [newValue, suffix] = prepareNumber(value, options);
  if (newValue === null) return null;

  const localeResult = newValue.toLocaleString(locale, {
    style:
      options.kurz === "nurwert"
        ? "decimal"
        : options.kurz === "mitsuffix"
        ? undefined
        : "currency",
    currency: options.waehrungsCode,
    currencyDisplay: "symbol",
    minimumFractionDigits: options.minDecimals ? options.minDecimals : 0,
    maximumFractionDigits: options.maxDecimals || undefined,
    useGrouping: true,
  });

  const currencySymbol =
    options.kurz === "mitsuffix"
      ? getCurrencySymbol(locale, options.waehrungsCode)
      : "";

  return `${localeResult}${suffix} ${currencySymbol}`;
}

// z.B. € oder CHF
export function getCurrencySymbol(
  locale: LocaleTag,
  waehungsCode: string
): string {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: waehungsCode,
  });
  const part = numberFormat.formatToParts(0).find((p) => p.type === "currency");
  return part ? part.value : "";
}

export type NumberParser = (string: string) => number;
//Source: https://observablehq.com/@mbostock/localized-number-parsing
export function createNumberParser(locale: string): NumberParser {
  const numberFormat = new Intl.NumberFormat(locale);
  const parts = numberFormat.formatToParts(12345.6);
  const numerals = [
    ...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210),
  ].reverse();

  const index = new Map(numerals.map((d, i) => [d, i]));
  const group = parts.find((d) => d.type === "group");
  const decimal = parts.find((d) => d.type === "decimal");

  const _group = new RegExp(`[${group ? group.value : ""}]`, "g");
  const _decimal = new RegExp(`[${decimal ? decimal.value : ""}]`);
  const _numeral = new RegExp(`[${numerals.join("")}]`, "g");
  const _index = (d: any, ..._: any[]) => index.get(d) as any;

  return (string) => {
    // aus der localized number eine javascript conforme number machen, die dann via typeconversion umgewandelt werden kannn
    string = string
      .trim()
      .replace(_group, "")
      .replace(_decimal, ".")
      .replace(_numeral, _index);
    return string ? +string : NaN;
  };
}

export function parseNumber(string: string): number {
  return 0.0;
  // return numberParser(string); //currentLocale.parseNumber
}

export function isValidNumber(string: string): boolean {
  // return !Number.isNaN(currentLocale.parseNumber(string));
  return true;
  // return !Number.isNaN(numberParser(string));
}
