import { gql } from "@apollo/client";
import {
  GET_AUFTRAEGE,
  GET_MEDIENARTEN,
  GET_ZEITANGABEN,
  GET_MELDUNGSDETAIL,
  SEARCH_MELDUNGEN,
  SEARCH_MELDUNGEN_NEXT,
  GET_AUTHORIZATION,
  GET_CONTAINER,
  GET_MELDUNGEN_BY_IDS,
} from "./queries";
import { Medienart } from "types/stammdaten/Medienart";
import { MeldungView } from "types/MeldungView";
import { SearchInput } from "types/Query";
import { LocaleTag } from "types/I18n";
import { Zeitangabe } from "types/Zeitangabe";
import { SearchResult } from "types/MeldungView";
import { Auftrag } from "types/Auftrag";
import {
  DELETE_MELDUNG,
  EXPORT_LISTE_LEEREN,
  EXPORT_MELDUNGEN,
  RESOLVE_QUERY,
  TOGGLE_ALL_MELDUNGEN,
  TOGGLE_MELDUNG,
} from "./mutations";
import { ExportMeldungenInput } from "types/ExportMeldungenInput";
import { AuthorizationResult } from "types/AuthorizationResult";
import { Container } from "types/Container";
import { ExportMeldungResult } from "types/ExportMeldungResult";
import { Suchfeld } from "types/query/Suchfeld";
import { gqlClient } from "./client";

// Suche der Meldungen
export async function getMeldungBySearchQueryGQL(
  input: SearchInput,
  locale: LocaleTag
): Promise<SearchResult> {
  const result = await gqlClient.query({
    query: gql(SEARCH_MELDUNGEN),
    variables: {
      search: input,
      localeTag: locale,
    },
  });
  return result.data.searchScrollResult;
}

export async function getNextMeldungBySearchQueryGQL(
  nextLink: string,
  locale: LocaleTag
): Promise<SearchResult> {
  const result = await gqlClient.query({
    query: gql(SEARCH_MELDUNGEN_NEXT),
    variables: {
      nextLink: nextLink,
      localeTag: locale,
    },
    fetchPolicy: "no-cache",
  });
  return result.data.searchScrollResultNext;
}

export async function getAuthentificationResult(): Promise<AuthorizationResult> {
  const result = await gqlClient.query({
    query: gql(GET_AUTHORIZATION),
  });

  return result.data.authorization;
}

export async function exportMeldungenGQL(
  exportMeldungenInput: ExportMeldungenInput
): Promise<ExportMeldungResult> {
  const result = await gqlClient.mutate({
    mutation: gql(EXPORT_MELDUNGEN),
    variables: { exportMeldungenInput },
  });

  return result.data.exportMeldungen;
}

export async function resolveQueryGQL(
  queryString: string
): Promise<Suchfeld[]> {
  const result = await gqlClient.mutate({
    mutation: gql(RESOLVE_QUERY),
    variables: { queryString },
  });

  return result.data.resolveQuery;
}

export async function toggleMeldungGQL(meldungsId: string): Promise<boolean> {
  const result = await gqlClient.mutate({
    mutation: gql(TOGGLE_MELDUNG),
    variables: { meldungsId },
  });
  return result.data.toggleMeldung;
}

export async function deleteMeldungGQL(meldungsId:string) :Promise<boolean> {
  const result = await gqlClient.mutate({
    mutation: gql(DELETE_MELDUNG),
    variables: { meldungsId },
  });
  return result.data.deleteMeldung;
}

export async function toggleAllMeldungenGQL(props: {
  searchQuery: SearchInput;
  areSomeSelected: boolean;
  locale: string;
}): Promise<boolean> {
  const result = await gqlClient.mutate({
    mutation: gql(TOGGLE_ALL_MELDUNGEN),
    variables: {
      search: props.searchQuery,
      areSomeSelected: props.areSomeSelected,
    },
  });
  return result.data.toggleMeldung;
}

export async function exportListeLeerenGQL(): Promise<boolean> {
  const result = await gqlClient.mutate({
    mutation: gql(EXPORT_LISTE_LEEREN),
  });
  return result.data.exportListeLeeren;
}

export async function getMedienartenGQL(): Promise<any> {
  const result = await gqlClient.query({
    query: gql(GET_MEDIENARTEN),
  });
  return result.data.medienart as Medienart[];
}

export async function getContainerGQL(locale: LocaleTag): Promise<Container> {
  const result = await gqlClient.query({
    query: gql(GET_CONTAINER),
    variables: {
      localeTag: locale,
    },
  });
  return result.data.container as Container;
}

export async function getExportListeGQL(): Promise<string[]> {
  const result = await gqlClient.query({
    query: gql(GET_CONTAINER),
  });
  const x =  result.data.container as Container;
  return x.exportliste as string[];
}

export async function getZeitangabenGQL(): Promise<Zeitangabe[]> {
  const result = await gqlClient.query({
    query: gql(GET_ZEITANGABEN),
  });

  // remapping der date angaben machen
  const x = (result.data.zeitangabe as Zeitangabe[]).map<Zeitangabe>((x) => ({
    bezeichner: x.bezeichner,
    name: x.name,
    zeitraum: {
      von: new Date(x.zeitraum.von).toISOString(),
      bis: new Date(x.zeitraum.bis).toISOString(),
      vordefinierterZeitpunkt: x.zeitraum.vordefinierterZeitpunkt,
      vordefinierterZeitraum: x.zeitraum.vordefinierterZeitraum,
    },
  }));

  return x;
}

export async function getMeldungenByIdsGQL(ids: string[]): Promise<MeldungView[]> {
  const result = await gqlClient.query({
    query: gql(GET_MELDUNGEN_BY_IDS),
    variables: {
      ids:ids
    }
  });

  return result.data.meldungen as MeldungView[];
}

export async function getMeldungByIdGQL(
  id: string,
  query: string
): Promise<any> {
  const result = await gqlClient.query({
    query: gql(GET_MELDUNGSDETAIL),
    variables: {
      id: id,
      query: query,
      localeTag: "de-DE",
    },
  });
  return result.data.meldung as MeldungView;
}

export async function getAuftraegeGQL(): Promise<any> {
  const result = await gqlClient.query({
    query: gql(GET_AUFTRAEGE),
  });
  return result.data.auftraege as Auftrag[];
}
