import { Button, IconButton, makeStyles, Popover } from "@material-ui/core";
import React, { useContext } from "react";
import LanguageIcon from "@material-ui/icons/Language";
import { LocaleContext } from "locales";
import { LocaleTag } from "types/I18n";
import { t } from "@lingui/macro";

export default function Sprachen() {
  const classes = useStyle();
  const { setLocaleContext } = useContext(LocaleContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleLanguageChange = (locale: LocaleTag) => {
    setLocaleContext(locale);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        className={classes.navigationButton}
        style={{ backgroundColor: "transparent" }}
      >
        <LanguageIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popoverContainer}>
          <Button
            className={classes.underNavigationButton}
            onClick={() => handleLanguageChange("de-DE")}
          >
            {t({
              id: "sprachen.deutsch",
              message: "Deutsch",
            })}
          </Button>
          <Button
            className={classes.underNavigationButton}
            onClick={() => handleLanguageChange("de-CH")}
          >
            {t({
              id: "sprachen.deutschCH",
              message: "Deutsch (CH)",
            })}
          </Button>
          <Button
            className={classes.underNavigationButton}
            onClick={() => handleLanguageChange("en-GB")}
          >
            {t({
              id: "sprachen.englischGB",
              message: "Englisch (GB)",
            })}
          </Button>
          <Button
            className={classes.underNavigationButton}
            onClick={() => handleLanguageChange("en-US")}
          >
            {t({
              id: "sprachen.englischUS",
              message: "Englisch (US)",
            })}
          </Button>
          <Button
            className={classes.underNavigationButton}
            onClick={() => handleLanguageChange("fr")}
          >
            {t({
              id: "sprachen.franzoesisch",
              message: "Französisch",
            })}
          </Button>
        </div>
      </Popover>
    </>
  );
}

const useStyle = makeStyles({
  popoverContainer: {
    background: "white",
    padding: 10,
    width: 160,
  },
  logoContainer: {
    width: 95,
    height: 45,
  },
  underNavigationButton: {
    fontSize: 13,
    textTransform: "none",
    cursor: "pointer",
    width: "100%",
    alignSelf: "left",
  },
  navigationButton: {
    fontSize: 13,
    textTransform: "none",
    "&:hover": {
      color: "#00C1BE",
    },
    cursor: "pointer",
    paddingRight: 0,
  },
});
