import { makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

type Props = {
  count: number;
  headline: string;
};

export default function DetailseiteInfoSkelton(props: Props) {
  const classes = useStyle();

  let dict = [];
  for (let index = 0; index < props.count; index++) {
    dict.push("");
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.headline}>{props.headline}</Typography>
      {dict.map(() => (
        <div className={classes.infoContainer}>
          <Skeleton className={classes.skeleton} />
          <Skeleton className={classes.skeleton} />
        </div>
      ))}
    </div>
  );
}

const useStyle = makeStyles({
  skeleton: {
    width: "50%",
  },
  container: {
    borderBottom: "1px solid lightGray",
    marginRight: 10,
    paddingBottom: 10,
  },
  headline: {
    color: "#00C1BE",
    textTransform: "uppercase",
    marginBottom: 5,
  },
  dict: {
    fontSize: 10,
  },
  infoContainer: {
    display: "grid",
    gridTemplateColumns: "40% 40% 20%",
    padding: 1,
    alignItems: "start",
  },
});
