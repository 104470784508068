import { makeStyles, Paper } from "@material-ui/core";
import { selectContainerState } from "redux/container";
import { changeFilter, selectFilterState } from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import CheckboxGroup from "./Core/CheckboxGroup";
import FilterChip from "./FilterChip";
import { getArrayAfterToggle } from "./Libs/shared";
import { t } from "@lingui/macro";
import TranslateSprachen from "components/ChipTranslations/SprachenChipTrans";

export default function SprachenChip() {
  const filter = useAppSelector(selectFilterState);
  const container = useAppSelector(selectContainerState);
  const dispatch = useAppDispatch();
  const classes = useStyle();

  const handleSpracheChanged = (value: string[]) => {
    const sprachen = getArrayAfterToggle(
      value,
      filter.sprachen,
      container.sprachen.map((s) => s.bezeichner).length
    );
    dispatch(
      changeFilter({
        ...filter,
        sprachen: sprachen,
      })
    );
  };

  return (
    <FilterChip
      xButtonBehaviour={() =>
        dispatch(
          changeFilter({
            ...filter,
            sprachen: container.sprachen.map((s) => s.bezeichner),
          })
        )
      }
      TitleComponent={Title}
      titleProps={{
        titleSprache: t({
          id: "sprachenChips.sprache",
          message: "Sprachen",
        }),
        titleSprachen: t({
          id: "sprachenChips.sprache",
          message: "Sprachen",
        }),
        titleKontinent: "Kontinent",
        total: container.sprachen.length,
        limit: 2,
        elements: container.sprachen
          .filter((s) => filter.sprachen?.includes(s.bezeichner))
          .map((s) => s.bezeichner),
      }}
      PopoverContentComponent={Popover}
      popoverProps={{
        paperClassName: classes.paper,
        header: t({
          id: "sprachenChips.sprache",
          message: "Sprachen",
        }),
        onChange: handleSpracheChanged,
        options: container.sprachen,
        selectedValues: filter.sprachen ?? [],
      }}
    />
  );
}

const useStyle = makeStyles({
  paper: {
    padding: "10px 15px",
    minWidth: 300,
  },
});

type TitleProps = {
  titleSprache: string;
  titleSprachen: string;
  titleKontinent: string;
  elements: string[];
  total: number;
  limit: number;
};

function Title(props: TitleProps): JSX.Element {
  const { elements, titleSprache, titleSprachen, limit, total } = props;

  if (!elements || elements.length === 0) return <>{"k.A."}</>;

  if (elements?.length > limit)
    return (
      <>
        {elements?.length + "/" + total} {titleSprachen}
      </>
    );

  if (elements?.length === 1)
    return (
      <>
        {titleSprache}: {elements?.join(", ")}
      </>
    );

  return (
    <>
      {titleSprachen}: {elements?.join(", ")}
    </>
  );
}

type KeyValuePair = {
  bezeichner: string;
  name: string;
};

type PopoverProps = {
  header: string;
  selectedValues: string[];
  options: KeyValuePair[];
  onChange: (values: string[]) => void;
  paperClassName: string;
};

function Popover(props: PopoverProps) {
  const translatedSprachen = TranslateSprachen();

  return (
    <Paper className={props.paperClassName} elevation={1}>
      <CheckboxGroup
        header={props.header}
        selectedValues={props.selectedValues}
        elements={translatedSprachen}
        onChange={props.onChange}
      ></CheckboxGroup>
    </Paper>
  );
}
