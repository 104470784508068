import { useParams } from "react-router";
import { changeFilter, getSearchQuery, setLoadLocation } from "redux/filter";
import { useAppDispatch } from "redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { initalZeitangabeCustom } from "redux/container";
import { Zeitangabe } from "types/Zeitangabe";

interface ParamType {
  searchQueryId: string;
}

type Props = {
  children: JSX.Element;
};

export default function SearchQueryWrapper(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { searchQueryId } = useParams<ParamType>();

  dispatch(getSearchQuery(searchQueryId))
    .then(unwrapResult)
    .then((result) => {
      if (result !== null) {
        let init: Zeitangabe = {
          bezeichner: initalZeitangabeCustom.bezeichner,
          name: initalZeitangabeCustom.name,
          zeitraum: {
            von: result.zeitraum?.von ?? "",
            bis: result.zeitraum?.bis ?? "",
          },
        };

        dispatch(setLoadLocation("query"));

        dispatch(
          changeFilter({
            herkunftslaender: result.herkunftsBezeichner ?? [],
            medienarten: result.medienartBezeichner ?? [],
            sprachen: result.spracheBezeichner ?? [],
            suchFelder: result.felder ?? [],
            zeitpunktVon: result.zeitraum?.von ?? "",
            zeitpunktBis: result.zeitraum?.bis ?? "",
            zeitangabe: init,
          })
        );
      }
    });

  return props.children;
}
