import { Button, makeStyles, Popover } from "@material-ui/core";
import React from "react";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { Trans } from "@lingui/macro";
import { useAppSelector } from "redux/store";
import { selectContainerState } from "redux/container";
import env from "env";
import { getMediaAccountUrl } from "lib/urls";

export default function Navigation() {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { accountName } = useAppSelector(selectContainerState);
  const { logoutUrl } = env;

  return (
    <>
      <Button
        onClick={handleClick}
        className={classes.navigationButton}
        style={{ backgroundColor: "transparent" }}
      >
        {accountName}
        <SettingsOutlinedIcon className={classes.icon} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popoverContainer}>
          <Button
            className={classes.underNavigationButton}
            onClick={() => window.open(getMediaAccountUrl(), "_blank")}
          >
            <Trans id="navigation.mediaaccount">Zur MediaAccount</Trans>
          </Button>

          {/* <Button
            className={classes.underNavigationButton}
            href="/documentation"
          >
            <Trans id="navigation.documentation">Dokumentation</Trans>
          </Button> */}

          <Button
            className={classes.underNavigationButton}
            onClick={() => window.open(logoutUrl, "_self")}
          >
            <Trans id="navigation.logout">Abmelden</Trans>
          </Button>
        </div>
      </Popover>
    </>
  );
}

const useStyle = makeStyles({
  popoverContainer: {
    background: "white",
    padding: 10,
    width: 228,
  },
  logoContainer: {
    width: 95,
    height: 45,
  },
  underNavigationButton: {
    fontSize: 13,
    textTransform: "none",
    cursor: "pointer",
    width: "100%",
    alignSelf: "left",
  },
  navigationButton: {
    fontSize: 13,
    textTransform: "none",
    "&:hover": {
      color: "#00C1BE",
    },
    cursor: "pointer",
  },
  icon: {
    marginLeft: 10,
  },
});
