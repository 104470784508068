import { makeStyles, Typography } from "@material-ui/core";
import { IconId, InfoDict } from "lib/converter";
import { Dislike, Like, Comment, Share, Eye } from "icons/EngagementsIcon";
import { Email, Webseite } from "icons/InfosIcon";

export type InfoProps = {
  headline: string;
  dict: InfoDict;
};

function getIcon(iconId?: IconId) {
  if (iconId === undefined) return <></>;

  switch (iconId) {
    case "like":
      return <Like color="#333" width="16" height="16" />;
    case "dislike":
      return <Dislike color="#333" width="16" height="16" />;
    case "comment":
      return <Comment color="#333" width="16" height="16" />;
    case "share":
      return <Share color="#333" width="16" height="16" />;
    case "eye":
      return <Eye color="#333" width="16" height="16" />;
    case "email":
      return <Email color="#333" width="16" height="16" />;
    case "webseite":
      return <Webseite color="#333" width="16" height="16" />;

    default:
      return <></>;
  }
}

export default function DetailseiteInfo(props: InfoProps) {
  const classes = useStyle();

  //Width muss noch angepasst werden + alles Responsive
  if (props.dict.length === 0) return <></>;
  return (
    <div className={classes.container}>
      <Typography className={classes.headline}>{props.headline}</Typography>
      {props.dict.map((el) => (
        <div className={classes.infoContainer}>
          <Typography variant="caption">{el.key}:</Typography>
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: el.value,
            }}
          ></Typography>
          {getIcon(el.iconId)}
        </div>
      ))}
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    borderBottom: "1px solid lightGray",
    marginRight: 10,
    paddingBottom: 10,
  },
  headline: {
    color: "#00C1BE",
    textTransform: "uppercase",
    marginBottom: 5,
  },
  dict: {
    fontSize: 10,
  },
  infoContainer: {
    display: "grid",
    gridTemplateColumns: "40% 40% 20%",
    padding: 1,
    alignItems: "start",
  },
});
