import Logo from "icons/Logo";
import { getLandingUrl, getMediaAccountUrl } from "lib/urls";
import { makeStyles } from "@material-ui/core";
import { t } from "@lingui/macro";
import env from "env";
import { ArrowLeft } from "icons/ArrowLeftIcon";

export default function NavigationLeft() {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <a href={getLandingUrl()}>
          <Logo />
        </a>
      </div>

      <div className={classes.productNameContainer}>{env.productName}</div>

      <div className={classes.separator} />

      <div
        className={classes.mediaaccountContainer}
        onClick={() => window.open(getMediaAccountUrl(), "_blank")}
      >
        <div className={classes.mediaaccountArrowIcon}>
          <ArrowLeft color="#7a7a7a" />
        </div>

        {t({
          id: "navigation.backto.mediaaccount",
          message: "Zurück zum MediaAccount",
        })}
      </div>
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    width: "100%",
  },
  logoContainer: {
    width: 95,
    height: 45,
  },
  mediaaccountContainer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    // fontWeight: "bold",
    fontSize: 16,
  },
  mediaaccountArrowIcon: {
    marginRight: 5,
    height: 24,
    width: 24,
  },
  productNameContainer: {
    fontWeight: "bold",
    fontSize: 16,
    marginLeft: 30,
  },
  separator: {
    borderLeft: "1px solid lightgray",
    alignSelf: "stretch",
    margin: "5px 0.5rem",
    marginLeft: 30,
    marginRight: 30,
  },
});
