import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { useContext, useEffect } from "react";
import { selectMeldungenState } from "redux/meldungen";
import { resetFilter } from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getContainer, selectContainerState } from "redux/container";
import { selectToastState } from "redux/toast";
import { getLandingUrl, getMeldungenUrl } from "lib/urls";
import { SESSION_LOCAL_STORAGE_KEY } from "types/constants";
import env from "env";
import Toast from "components/Toast";
import MeldungsDetail from "pages/MeldungsDetail";
import Meldungen from "pages/Meldungen";
import PrivateComponentWrapper from "PrivateComponentWrapper";
import MeldungsLoading from "components/Loading/MeldungsLoading";
import Documentation from "pages/Docu";
import Playground from "pages/Playground";
import SearchQueryWrapper from "SearchQueryWrapper";
import Landing from "pages/Landing";
import PageToTop from "components/PageToTop/pageToTop";
import { LocaleContext } from "locales";

export default function AppRoutes() {
  const dispatch = useAppDispatch();

  const containerState = useAppSelector(selectContainerState);
  const meldungenState = useAppSelector(selectMeldungenState);
  const { localeContext } = useContext(LocaleContext);
  const toast = useAppSelector(selectToastState);

  useEffect(() => {
    dispatch(getContainer(localeContext));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filter reset
    if (containerState.status === "idle") {
      dispatch(
        resetFilter({
          zeitangaben: containerState.zeitangaben,
          medienarten: containerState.medienarten,
          sprachen: containerState.sprachen,
          herkunftslaender: containerState.herkunftslaender,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerState.status]);

  const showLoading =
    meldungenState.status === "loading" ||
    meldungenState.status === "mehr-loading";

  const mainLoading = containerState.status === "loading";
  return (
    <>
      <MeldungsLoading show={showLoading} />
      <Router>
        <PageToTop />
        <Switch>
          <Route
            exact
            path="/start"
            render={() => (
              <PrivateComponentWrapper loading={mainLoading}>
                <Landing />
              </PrivateComponentWrapper>
            )}
          />

          <Route
            path={"/documentation/:markdownPath*"}
            render={() => <Documentation />}
          />
          <Route path={"/playground"} render={() => <Playground />} />

          <Route
            exact
            path="/search/query/:searchQueryId"
            render={() => (
              <PrivateComponentWrapper loading={mainLoading}>
                <SearchQueryWrapper>
                  <Redirect to={getMeldungenUrl()} />
                </SearchQueryWrapper>
              </PrivateComponentWrapper>
            )}
          />

          <Route
            exact
            path="/search/meldungen"
            render={() => (
              <PrivateComponentWrapper loading={mainLoading}>
                <Meldungen />
              </PrivateComponentWrapper>
            )}
          />
          <Route
            exact
            path={"/search/meldungen/:meldungDetailId+"}
            render={() => (
              <PrivateComponentWrapper loading={mainLoading}>
                <MeldungsDetail />
              </PrivateComponentWrapper>
            )}
          />

          <Route
            exact
            path={"/gateway/:sessionId"}
            render={() => (
              <PrivateComponentWrapper loading={mainLoading}>
                <Redirect to="/" />
              </PrivateComponentWrapper>
            )}
          />

          <Route
            path="/authenticate/:sessionId"
            render={() => (
              <PrivateComponentWrapper loading={mainLoading}>
                <Redirect to="/" />
              </PrivateComponentWrapper>
            )}
          />

          <Route
            path="/login"
            component={() => {
              window.location.href = env.loginUrl;
              return null;
            }}
          />

          <Route
            path="*"
            render={() => (
              <PrivateComponentWrapper loading={mainLoading}>
                <Redirect to={getLandingUrl()} />
              </PrivateComponentWrapper>
            )}
          ></Route>

          {/* für einfaches debuggen */}
          <Route exact path="/">
            {localStorage.getItem(SESSION_LOCAL_STORAGE_KEY) ? (
              <Redirect to={getLandingUrl()} />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        </Switch>
      </Router>
      <Toast variant={toast.variant} open={toast.open} text={toast.text} />
    </>
  );
}
