import { Trans } from "@lingui/macro";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import AppBar from "components/AppBar";
import Kachel from "components/Kachel";
import { LocaleContext } from "locales";
import { selectFilterState, resetFilter, setLoadLocation } from "redux/filter";
import {
  BuildQueryFromFilterState,
  getMeldungen,
  getNextMeldungen,
  selectMeldungenState,
  toggleAllMeldungen,
  toggleMeldung,
} from "redux/meldungen";
import { useAppDispatch, useAppSelector } from "redux/store";
import { resetNavigationIndex } from "redux/meldungNavigation";
import Seperator from "components/Seperator";
import MeldungenEmpty from "components/MeldungenEmpty";
import ListenExport from "components/ListenExport";
import { MeldungView } from "types/MeldungView";
import { getExportListe, selectContainerState } from "redux/container";
import ErweiterteSuchleiste from "components/ErweiterteSuchleiste";
import KachelSkelton from "components/Kachel/KachelSkelton";
import Checkbox from "components/Checkbox";
import AnsichtToggle from "components/AnsichtToggle";
import { getBatchAggregation, selectChartState } from "redux/chart";
import Zeitchart from "components/Charts/Zeitchart";
import { primaryColor } from "theme";
import { t } from "@lingui/macro";
import ScrollToTopButton from "components/ScrollToTopButton/scrollToTop";
import TitelChart from "components/Charts/Titelchart";

export default function Meldungen() {
  const meldungsCount = 20;

  const classes = useStyle();
  const dispatch = useAppDispatch();
  const [areAllSelected, setAreAllSelected] = useState<boolean>(false);
  const [selectAllLoading, setSelectAllLoading] = useState<boolean>(false);

  const { localeContext } = useContext(LocaleContext);

  const filterState = useAppSelector(selectFilterState);
  const meldungenState = useAppSelector(selectMeldungenState);
  const containerState = useAppSelector(selectContainerState);
  const chartState = useAppSelector(selectChartState);

  useEffect(() => {
    dispatch(resetNavigationIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterState.suchFelder?.length !== 0) {
      const searchQuery = BuildQueryFromFilterState(
        filterState,
        0,
        meldungsCount
      );

      setAreAllSelected(false);
      dispatch(
        getMeldungen({
          searchQuery: searchQuery,
          locale: localeContext,
          containerState,
        })
      );

      const batchQueryInput = {
        query: searchQuery.query,
        zeit: true,
        gesamt: true,
        titel: true,
      };

      dispatch(
        getBatchAggregation({
          batchAggregationInput: batchQueryInput,
          localeTag: localeContext,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  useEffect(() => {
    if (containerState.exportliste.length === 0) setAreAllSelected(false);
  }, [containerState.exportliste.length]);

  const onMehrClick = () => {
    dispatch(
      getNextMeldungen({
        nextLink: meldungenState.nextLink,
        locale: localeContext,
      })
    );
  };

  const onSelectAll = () => {
    setSelectAllLoading(true);
    const searchQuery = BuildQueryFromFilterState(
      filterState,
      0,
      containerState.toggleMeldungenMaximaleAnzahl
    );
    dispatch(
      toggleAllMeldungen({
        searchQuery,
        areSomeSelected: !areAllSelected,
        locale: localeContext,
      })
    ).then(() => {
      dispatch(getExportListe()).finally(() => {
        setSelectAllLoading(false);
      });
      setAreAllSelected(!areAllSelected);
    });
  };

  return (
    <div className={classes.container}>
      {containerState.status === "idle" && (
        <>
          <AppBar />
          <Seperator />
          <ErweiterteSuchleiste />
          <Seperator />
          <ScrollToTopButton />

          <div className={classes.subheaderContainer}>
            <div className={classes.text}>
              {containerState.status === "idle" &&
                meldungenState.count !== 0 && (
                  <div className={classes.meldungsCountContainer}>
                    {containerState.toggleAllerMeldungenErlaubt && (
                      <Checkbox
                        loading={selectAllLoading}
                        checked={
                          areAllSelected &&
                          containerState.exportliste.length > 0
                        }
                        onToggle={onSelectAll}
                        className={classes.selectAllCheckbox}
                      />
                    )}
                    <Typography variant="body1">
                      {meldungenState.formattedCount}{" "}
                      <Trans id="begriff.meldungen">Meldungen</Trans>
                    </Typography>
                  </div>
                )}
            </div>
            <AnsichtToggle />
            <ListenExport />
          </div>
          <Seperator />
          {containerState.ansicht === "chart" ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Zeitchart
                    title={t({
                      id: "chart.anzahlMeldungen",
                      message: "Zeitverlauf | Meldungen | Anzahl",
                    })}
                    color={primaryColor}
                    data={chartState.zeit}
                    dataTitelBool={false}
                    xAxisDataKey="datum"
                    yAxisDataKey="anzahlMeldungen"
                    dataDescription="Anzahl"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Zeitchart
                    title={t({
                      id: "chart.reichweiteGesamt",
                      message: "Zeitverlauf | Meldungen | Reichweite",
                    })}
                    color={"#FF0004"}
                    data={chartState.zeit}
                    dataTitelBool={false}
                    xAxisDataKey="datum"
                    yAxisDataKey="reichweiteGesamt"
                    dataDescription="Reichweite"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TitelChart
                    title={t({
                      id: "chart.toptitel",
                      message: "Top 20 Titel | Reichweite",
                    })}
                    color={"#FF8C8C"}
                    data={chartState.titel}
                    dataTitelBool={true}
                    xAxisDataKey="wertAggregation"
                    yAxisDataKey="titelName"
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {meldungenState.status !== "loading" &&
              meldungenState.count === 0 ? (
                <MeldungenEmpty
                  onResetFilter={() => {
                    dispatch(setLoadLocation("none"));
                    dispatch(
                      resetFilter({
                        zeitangaben: containerState.zeitangaben,
                        medienarten: containerState.medienarten,
                        sprachen: containerState.sprachen,
                        herkunftslaender: containerState.herkunftslaender,
                      })
                    );
                  }}
                />
              ) : (
                <div className={classes.grid}>
                  {meldungenState.status !== "loading" &&
                    meldungenState.elements.map((a: MeldungView) => {
                      return (
                        <Kachel
                          query={filterState.suche ?? ""}
                          meldung={a}
                          selected={containerState.exportliste.includes(
                            a.meldungDetailUrl.replace("meldung/", "")
                          )}
                          exported={
                            a.exportInformation?.exportStatus === "Exportiert"
                          }
                          loadingToggle={meldungenState.toggleStatusId === a.id}
                          onToggle={() =>
                            dispatch(toggleMeldung(a)).then(() =>
                              dispatch(getExportListe())
                            )
                          }
                        />
                      );
                    })}
                  {meldungenState.status === "loading" &&
                    [...Array(meldungsCount).keys()].map((d) => (
                      <KachelSkelton />
                    ))}
                  {meldungenState.status === "mehr-loading" &&
                    [...Array(4).keys()].map((d) => <KachelSkelton />)}
                </div>
              )}

              {meldungenState.status === "failed" && (
                <div>Ein Fehler ist aufgetreten</div>
              )}

              {meldungenState.nextLink &&
                meldungenState.count !== 0 &&
                meldungenState.elements.length < meldungenState.count &&
                meldungenState.status !== "mehr-loading" &&
                meldungenState.status !== "loading" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      className={classes.mehrButton}
                      onClick={onMehrClick}
                    >
                      <Trans id="begriff.mehr">Mehr</Trans>
                    </Button>
                  </div>
                )}
            </>
          )}
        </>
      )}
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "15px",
  },
  meldungsCountContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    color: "gray",
  },
  grid: {
    display: "grid",
    gap: 30,
    gridTemplateColumns: "repeat(auto-fill, minmax(30em, 1fr))",
    marginBottom: 10,
    height: "100%",
  },
  mehrButton: {
    display: "block",
    width: "16em",
    margin: "0px auto 1em",
    background: "#00c1be",
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "0.75rem",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: "6px",
    paddingBottom: "6px",
    textAlign: "center",
    lineHeight: "initial",
    borderRadius: 0,
    "&:hover": {
      background: "#00ADAB",
    },
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
    textAlign: "center",
  },
  subheaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
  },
  exportButton: {
    cursor: "pointer",
  },
  selectAllCheckbox: {
    cursor: "pointer",
    marginRight: 10,
  },
});
