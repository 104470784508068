import { makeStyles, Paper } from "@material-ui/core";
import { selectContainerState } from "redux/container";
import { changeFilter, selectFilterState } from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import CheckboxGroup from "./Core/CheckboxGroup";
import FilterChip from "./FilterChip";
import { getArrayAfterToggle } from "./Libs/shared";
import { t } from "@lingui/macro";
import TranslateLaender from "components/ChipTranslations/LaenderChipTrans";

export default function SprachenChip() {
  const filter = useAppSelector(selectFilterState);
  const container = useAppSelector(selectContainerState);
  const dispatch = useAppDispatch();
  const classes = useStyle();

  const handleSpracheChanged = (value: string[]) => {
    const herkunftslaender = getArrayAfterToggle(
      value,
      filter.herkunftslaender,
      container.herkunftslaender.map((h) => h.bezeichner).length
    );
    dispatch(
      changeFilter({
        ...filter,
        herkunftslaender: herkunftslaender,
      })
    );
  };

  return (
    <FilterChip
      xButtonBehaviour={() =>
        dispatch(
          changeFilter({
            ...filter,
            herkunftslaender: container.herkunftslaender.map(
              (h) => h.bezeichner
            ),
          })
        )
      }
      TitleComponent={Title}
      titleProps={{
        titleLand: "Land",
        titleLaender: "Länder",
        titleKontinent: t({
          id: "laenderChip.kontinent",
          message: "Kontinent",
        }),
        total: container.herkunftslaender.length,
        limit: 2,
        elements: container.herkunftslaender
          .filter((s) => filter.herkunftslaender?.includes(s.bezeichner))
          .map((s) => s.bezeichner.toUpperCase()),
      }}
      PopoverContentComponent={Popover}
      popoverProps={{
        paperClassName: classes.paper,
        header: t({
          id: "laenderChip.laender",
          message: "Länder",
        }),
        onChange: handleSpracheChanged,
        options: container.herkunftslaender,
        selectedValues: filter.herkunftslaender ?? [],
      }}
    />
  );
}

const useStyle = makeStyles({
  paper: {
    padding: "10px 15px",
    minWidth: 300,
  },
});

type TitleProps = {
  titleLand: string;
  titleLaender: string;
  titleKontinent: string;
  elements: string[];
  total: number;
  limit: number;
};

function Title(props: TitleProps): JSX.Element {
  const { elements, titleLand, titleLaender, titleKontinent, limit, total } =
    props;

  if (!elements || elements.length === 0) return <>{"k.A."}</>;

  if (elements.length === total)
    return (
      <>
        {titleKontinent}:{" "}
        {t({
          id: "laenderChip.europa",
          message: "Europa",
        })}
      </>
    );

  if (elements?.length > limit)
    return (
      <>
        {elements?.length + "/" + total} {titleLaender}
      </>
    );

  if (elements?.length === 1)
    return (
      <>
        {titleLand}: {elements?.join(", ")}
      </>
    );

  return (
    <>
      {titleLaender}: {elements?.join(", ")}
    </>
  );
}

type KeyValuePair = {
  bezeichner: string;
  name: string;
};

type PopoverProps = {
  header: string;
  selectedValues: string[];
  options: KeyValuePair[];
  onChange: (values: string[]) => void;
  paperClassName: string;
};

function Popover(props: PopoverProps) {
  const translatedLaender = TranslateLaender();

  return (
    <Paper className={props.paperClassName} elevation={1}>
      <CheckboxGroup
        header={props.header}
        selectedValues={props.selectedValues}
        elements={translatedLaender}
        onChange={props.onChange}
      ></CheckboxGroup>
    </Paper>
  );
}
