import { createTheme } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

export const primaryColor = "#00C1BE";
export const secondaryColor = "white";
export const grayColor = "#3E3E3E";
export const disabledColor = "lightgray";

const textColor = "#7A7A7A";
const textHeadlineColor = "#000000";

const themeDebug = createTheme({
  textHighlight: {
    backgroundColor: alpha(primaryColor, 0.2),
    color: "red",
  },
  textColor: textColor,
  textHeadlineColor: textHeadlineColor,
  palette: {
    primary: {
      light: primaryColor,
      main: primaryColor,
      dark: primaryColor,
      contrastText: primaryColor,
    },
  },
  overrides: {
    MuiInput: {
      formControl: {
        fontSize: 12,
      },
    },
    MuiChip: {
      deleteIcon: {
        color: "white",
      },
    },
    MuiCheckbox: {
      root: {
        color: primaryColor,
      },
    },
  },
  typography: {
    fontFamily: ["Roboto Condensed"].join(","),
    body1: {
      fontSize: 12,
    },
  },
});

export default themeDebug;
