import { t } from "@lingui/macro";
import {
  Chip,
  CircularProgress,
  Grid,
  Tooltip,
  makeStyles,
  Input,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import Button from "components/Buttons/Button";
import {
  getLabelValidationTextEmpty,
  getLabelValidationTextMaxLength,
} from "components/EditableChip/validations";
import Seperator from "components/Seperator";
import { useEffect, useState } from "react";
import { Auftrag } from "types/Auftrag";

type AuftragsauswahlPanelProps = {
  auftraege: Auftrag[];
  selectedAuftrag?: Auftrag;
  meldungsCount: number;

  onExport: (auftrag: Auftrag, labels: string[]) => void;
  onCancel: () => void;

  exportStatus: "idle" | "loading" | "failed";
};

export default function AuftragsauswahlPanel(props: AuftragsauswahlPanelProps) {
  const labelname = "Import " + new Date().toLocaleDateString();
  const maxLength = 50;

  const [auftrag, setAuftrag] = useState(props.selectedAuftrag);
  const [label, setLabel] = useState(labelname);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const classes = useStyles({ error });

  useEffect(() => {
    if (label.length >= maxLength) {
      setError(true);
      setErrorText(getLabelValidationTextMaxLength(maxLength));
    } else if (label.length <= 0) {
      setError(true);
      setErrorText(getLabelValidationTextEmpty());
    } else {
      setError(false);
      setErrorText("");
    }
  }, [label]);

  const handleInputChange = (value: string) => {
    if (value.length < maxLength) {
      setError(false);
      setErrorText("");
    }

    setLabel(value);
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        className={classes.grid}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item className={classes.text}>
          {props.meldungsCount}{" "}
          {t({
            id: "begriff.auftrag.header",
            message: "Meldungen in den MediaAccount importieren",
          })}
        </Grid>

        <Grid item>
          <div className={classes.headerText}>
            {t({
              id: "begriff.auftrag.auswahl",
              message:
                "Welchem Auftrag sollen die Meldungen zugeordnet werden?",
            })}
          </div>
          <Select
            className={classes.auftragsSelect}
            value={auftrag?.id}
            disableUnderline
            label="Auftrag"
            defaultValue="Auftrag wählen"
            displayEmpty={true}
            onChange={(e) =>
              setAuftrag(props.auftraege.find((x) => x.id === e.target.value))
            }
          >
            {props.auftraege.map((a) => (
              <MenuItem value={a.id} key={a.id}>
                <div className={classes.selectionItem}>
                  {a.auftragsNummer} - {a.bezeichnung}
                </div>
              </MenuItem>
            ))}
          </Select>

          <Seperator />

          <div className={classes.headerText}>
            {t({
              id: "begriff.label",
              message:
                "Welches Label sollen die Meldungen erhalten? (max. 50 Zeichen)",
            })}
          </div>

          <div>
            <Tooltip title={errorText}>
              <Input
                disableUnderline
                className={classes.labelInput}
                value={label}
                onChange={(e) => handleInputChange(e.target.value)}
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(
                    0,
                    e.currentTarget.value.length
                  )
                }
              />
            </Tooltip>
          </div>
          <div className={classes.labelVorschauContainer}>
            <span className={classes.labelVorschauText}>
              {t({ id: "begriff.label.vorschau", message: "Label-Vorschau" })}:{" "}
            </span>
            <Chip label={label} className={classes.labelVorschauChip} />
          </div>
        </Grid>

        {props.exportStatus === "loading" ? (
          <div className={classes.progress}>
            <CircularProgress size={36.5} />
          </div>
        ) : (
          <Grid item className={classes.buttonGrid}>
            <Button
              className={classes.buttonCancel}
              text={t({
                id: "listenimport.header.cancel.button",
                message: "Abbrechen",
              })}
              variant="secondary"
              onClick={props.onCancel}
            />
            <Button
              text={t({
                id: "listenimport.header.export.button",
                message: "Exportieren",
              })}
              variant="primary"
              disabled={error}
              onClick={() => props.onExport(auftrag, [label])}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles({
  paper: {
    width: "550px",
    height: "300px",
    padding: "25px",
    borderRadius: "12px",
    boxShadow: "0 0px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14)",
  },
  labelInput: {
    margin: "5px 0px",
    width: "100%",
    borderRadius: "6px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: (props: any) => (props.error ? "red" : "#E8E8E8"),
    "& .MuiInputBase-input": {
      paddingLeft: "10px",
      color: "#3E3E3E",
    },
  },
  labelVorschauContainer: {
    margin: "5px 0px",
  },
  labelVorschauText: {
    color: "#3E3E3E",
  },
  labelVorschauChip: {
    marginLeft: "5px",
    borderRadius: "6px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#E8E8E8",
    maxWidth: "409px",
    height: "25px",
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiChip-label": {
      padding: "0px 10px",
      color: "#3E3E3E",
    },
    scrollbarColor: "transparent",
  },
  grid: {
    height: "100%",
  },
  text: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#3E3E3E",
    marginBottom: "20px",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#3E3E3E",
  },
  selectionLabel: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  auftragsSelect: {
    width: "100%",
    borderRadius: "6px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#E8E8E8",
    margin: "5px 0px",
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    scrollbarColor: "transparent",
  },
  selectionItem: {
    paddingLeft: "10px",
    color: "#3E3E3E",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonCancel: {
    marginRight: "10px",
  },
  progress: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
