import { LocaleTag } from "types/I18n";

export const shortNumericDate: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

export const shortTime: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

export const shortNumericDateWithTime: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export type Formatter = {
  shortTime: Intl.DateTimeFormat;
  shortNumericDate: Intl.DateTimeFormat;
  shortNumericDateWithTime: Intl.DateTimeFormat;
  weekdayNarrow: Intl.DateTimeFormat;
  weekdayShort: Intl.DateTimeFormat;
  weekdayLong: Intl.DateTimeFormat;
  monthNarrow: Intl.DateTimeFormat;
  monthShort: Intl.DateTimeFormat;
  monthLong: Intl.DateTimeFormat;
  // relativeDateDistance: IntlRelativeTimeFormatFix;
};

const formatters: { [K in LocaleTag]?: Formatter } = {};

export const getFormatter = (localeName: LocaleTag): Formatter => {
  if (!formatters[localeName]) {
    formatters[localeName] = {
      shortTime: new Intl.DateTimeFormat(localeName, shortTime),
      shortNumericDate: new Intl.DateTimeFormat(localeName, shortNumericDate),
      shortNumericDateWithTime: new Intl.DateTimeFormat(
        localeName,
        shortNumericDateWithTime
      ),

      weekdayNarrow: new Intl.DateTimeFormat(localeName, { weekday: "narrow" }),
      weekdayShort: new Intl.DateTimeFormat(localeName, { weekday: "short" }),
      weekdayLong: new Intl.DateTimeFormat(localeName, { weekday: "long" }),

      monthNarrow: new Intl.DateTimeFormat(localeName, { month: "narrow" }),
      monthShort: new Intl.DateTimeFormat(localeName, { month: "short" }),
      monthLong: new Intl.DateTimeFormat(localeName, { month: "long" }),

      // relativeDateDistance: new IntlRelativeTimeFormatFix("en", {
      //   numeric: "auto",
      //   style: "long",
      // }),
    };
  }

  return formatters[localeName] as Formatter;
};

export const currentFormatter = (locale: LocaleTag): Formatter =>
  getFormatter(locale);
