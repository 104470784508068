import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

type ToastVariantType = "success" | "warning" | "error" | "info"| undefined;

export type ToastStateType = {
    open: boolean;
    variant: ToastVariantType;
    text: string;
};

const initialState: ToastStateType = {
    open: false,
    variant: undefined,
    text: "",
};

export const toastSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    showToast: (state, action: PayloadAction<{variant: ToastVariantType,text: string}>) => {
        state.text= action.payload.text
        state.variant= action.payload.variant
        state.open= true
    },
    resetToast: (state) =>{
        state.open= false
    }
  }
});

export const {
    showToast,resetToast
} = toastSlice.actions;

export const selectToastState = (state: RootState) =>
  state.toast;

export default toastSlice.reducer;