import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

export function MediasSkelton() {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Skeleton
        variant="rect"
        className={classes.images}
        width={250}
        height={300}
      />
    </div>
  );
}

const useStyle = makeStyles({
  root: {
    paddingTop: 15,
  },
  images: {
    borderRadius: 10,
    display: "block",
    justifyContent: "center",
    maxWidth: 500,
    maxHeight: 550,
  },
  navigationButtonContainer: {
    height: 50,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexFlow: "wrap",
  },
  navigationButton: {
    borderRadius: 500,
    marginRight: 2,
    marginLeft: 2,
    height: 10,
    width: 10,
  },
});
