import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { Unchecked, Checked, Hover } from "icons/CheckboxeIcon";
import { CircularProgress } from "@material-ui/core";

type CheckboxProps = {
  className?: string;

  loading: boolean;
  disabled?: boolean;
  checked: boolean;

  onToggle: () => void;
};
type StyleProps = {
  checked: boolean;

  disabled?: boolean;
  hover: boolean;
};

export default function Checkbox(props: CheckboxProps) {
  const [hover, setHover] = useState(false);

  const classes = useStyles({ checked: props.checked, hover });

  function handleToogle() {
    if (props.disabled) return;

    props.onToggle();
  }

  function handleHover(hover: boolean) {
    if (props.disabled) return;

    setHover(hover);
  }

  if (props.loading)
    return <CircularProgress size={26} className={classes.loading} />;

  return (
    <div
      onClick={() => handleToogle()}
      onMouseOver={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      className={props.className}
    >
      <div className={classes.imageChecked}>
        <Checked disabled={props.disabled ?? false} />
      </div>
      <div className={classes.imageUnchecked}>
        <Unchecked disabled={props.disabled ?? false} />
      </div>
      <div className={classes.imageHover}>
        <Hover disabled={props.disabled ?? false} />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  loading: {
    // padding: 0,
    marginRight: 11,
    marginBottom: 11,
  },

  imageChecked: {
    display: (props: StyleProps) => (props.checked ? "block" : "none"),
    height: 26,
  },
  imageUnchecked: {
    display: (props: StyleProps) =>
      !props.checked && !props.hover ? "block" : "none",
    color: "black",
    height: 26,
  },
  imageHover: {
    display: (props: StyleProps) =>
      !props.checked && props.hover ? "block" : "none",
    height: 26,
  },
});
