import { IconProps } from "types/IconProps";

export function Email(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={props.color}
      width={props.width ?? "24px"}
      height={props.height ?? "24px"}
    >
      <g data-name="Layer 2">
        <g data-name="email">
          <rect width="24" height="24" opacity="0" />
          <path d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z" />
        </g>
      </g>
    </svg>
  );
}

export function Webseite(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={props.color}
      width={props.width ?? "24px"}
      height={props.height ?? "24px"}
    >
      <g data-name="Layer 2">
        <g data-name="browser">
          <rect width="24" height="24" opacity="0" />
          <path d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm1 15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7h14zM5 9V6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3z" />
          <circle cx="8" cy="7.03" r="1" />
          <circle cx="12" cy="7.03" r="1" />
        </g>
      </g>
    </svg>
  );
}
