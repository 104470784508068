import { selectContainerState } from "redux/container";
import { changeFilter, selectFilterState } from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import { Bedingung } from "types/query/Bedingung";
import { Modifier } from "types/query/Modifier";
import { Suchfeld } from "types/query/Suchfeld";
import QueryChip from "./Chips/Core/QueryChip";

export default function SuchChips() {
  const filter = useAppSelector(selectFilterState);
  const container = useAppSelector(selectContainerState);
  const dispatch = useAppDispatch();
  const onToggleQueryFeld = (index: number, value: string) => {
    //get der betroffene suchfeld
    const betroffeneSuchfeld = filter.suchFelder[index];

    let updatedSuchFeld: Suchfeld = undefined;

    //gucken ob feld noch existiert
    const isFieldAlreadySelected = betroffeneSuchfeld.feld.includes(value);

    //wenn dann entferne ich es sonst hinzufuege ich es
    if (isFieldAlreadySelected)
      updatedSuchFeld = {
        ...betroffeneSuchfeld,
        feld: betroffeneSuchfeld.feld.filter((f) => f !== value),
      };
    else
      updatedSuchFeld = {
        ...betroffeneSuchfeld,
        feld: [...betroffeneSuchfeld.feld, value],
      };
    safeUpdateSuchFeld(updatedSuchFeld, index);
  };

  const onQueryChange = (index: number, value: string, key: string) => {
    //get bezeichner by feldname (modifier / bedingung)
    const bezeichner: string =
      container.bedingungen.find((f: Bedingung) => f.bezeichner === value)
        ?.bezeichner ??
      container.modifiers.find((f: Modifier) => f.bezeichner === value)
        ?.bezeichner;

    // get der betroffene suchfeld
    const alteSuchFeld = filter.suchFelder[index];

    //update suchfeld
    const updatedSuchfeld: Suchfeld = {
      ...alteSuchFeld,
      [key]: bezeichner,
    };

    safeUpdateSuchFeld(updatedSuchfeld, index);
  };

  const onDeleteItem = (index: number) => {
    const suchFelderCopy = [...filter.suchFelder];
    suchFelderCopy.splice(index, 1);
    dispatch(changeFilter({ ...filter, suchFelder: suchFelderCopy }));
  };

  const safeUpdateSuchFeld = (updatedSuchfeld: Suchfeld, index: number) => {
    const nichtBetroffeneSuchfelder = filter.suchFelder.filter(
      (f) => f.query !== updatedSuchfeld.query
    );
    insertAt(nichtBetroffeneSuchfelder, index, updatedSuchfeld);
    dispatch(
      changeFilter({ ...filter, suchFelder: nichtBetroffeneSuchfelder })
    );
  };

  function insertAt(array, index, element) {
    array.splice(index, 0, element);
  }
  return (
    <>
      {filter.suchFelder.map((suchfeld, index) => {
        return (
          <QueryChip
            negative={suchfeld.bedingung === "mustnot"}
            bedingung={
              container.bedingungen.find(
                (f) => f.bezeichner === suchfeld.bedingung
              ).name
            }
            modifier={
              container.modifiers.find(
                (f) => f.bezeichner === suchfeld.modifier
              ).name
            }
            suchfeld={suchfeld}
            onChange={(value, modifier) =>
              onQueryChange(index, value, modifier)
            }
            onToggle={(value) => onToggleQueryFeld(index, value)}
            query={suchfeld.query}
            onDelete={() => onDeleteItem(index)}
          />
        );
      })}
    </>
  );
}
