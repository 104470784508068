import { t } from "@lingui/macro";

export function getLabelValidationTextMaxLength(maxLength: number) {
  return (
    t({
      id: "label.validation.maxlength",
      message: "Die maximale Zeichenlänge für das Label erreicht: ",
    }) + maxLength
  );
}

export function getLabelValidationTextEmpty() {
  return t({
    id: "label.validation.empty",
    message: "Das Label darf nicht leer sein.",
  });
}
