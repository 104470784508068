import { Checkbox, FormLabel, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { primaryColor } from "theme";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { t } from "@lingui/macro";
import { ArrowRightCircle } from "icons/ArrowRightCircleIcon";

export type KeyValuePair = {
  bezeichner: string;
  name: string;
};

type CheckBoxGroupType = {
  elements: KeyValuePair[];
  selectedValues: string[];
  onChange: (value: string[]) => void;
  header: string;
  expandable?: boolean;
  onSelectAll?: () => void;
  onSelectSpecific?: (value: string[]) => void;
};

export default function CheckboxGroup(props: CheckBoxGroupType) {
  const [expanded, setExpanded] = useState<boolean>(!props.expandable);
  const classes = useStyle({
    expandable: props.expandable,
    expanded: expanded,
  });

  const expand = () => {
    if (props.expandable) setExpanded((prev) => !prev);
    else return;
  };

  const allSelected = () => {
    const bezeichners = props.elements.map((e) => e.bezeichner);
    const drin = bezeichners.filter((b) => props.selectedValues.includes(b));
    switch (drin.length) {
      case 0:
        return "none";
      case bezeichners.length:
        return "all";
      default:
        return "some";
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        {props.expandable ? (
          <ExpandableCheckboxHeader
            onSelectSpecific={() =>
              props.onSelectSpecific(props.elements.map((e) => e.bezeichner))
            }
            expand={expand}
            selectedState={allSelected()}
            onSelectAll={props.onSelectAll}
            expanded={expanded}
            header={props.header}
          />
        ) : (
          <FormLabel component="legend" className={classes.header}>
            {props.header}
          </FormLabel>
        )}
      </div>
      {expanded &&
        props.elements.map((element: KeyValuePair, i: number) => (
          <div className={classes.checkboxElement} key={element.bezeichner}>
            <Typography variant="body1" className={classes.label}>
              {element.name}
            </Typography>
            <div style={{ display: "flex" }}>
              {props.onSelectSpecific && (
                <button
                  className={classes.selectOneButton}
                  onClick={() => props.onSelectSpecific([element.bezeichner])}
                >
                  <ArrowRightCircle
                    color={primaryColor}
                    width="12"
                    height="12"
                    title={t({
                      id: "filter.eintragAuswaehlen",
                      message: "Nur diesen Eintrag auswählen",
                    })}
                  />
                </button>
              )}
              <Checkbox
                key={`${element.bezeichner}-${i}`}
                value={element.bezeichner}
                checked={props.selectedValues.includes(element.bezeichner)}
                onChange={() => props.onChange([element.bezeichner])}
                size="small"
                color="primary"
              />
            </div>
          </div>
        ))}
    </div>
  );
}

type ExpandableCheckboxHeaderProps = {
  expand: () => void;
  selectedState: "some" | "all" | "none";
  header: string;
  onSelectAll: () => void;
  onSelectSpecific?: () => void;
  expanded: boolean;
};

function ExpandableCheckboxHeader(props: ExpandableCheckboxHeaderProps) {
  const {
    expand,
    selectedState,
    header,
    onSelectAll,
    expanded,
    onSelectSpecific,
  } = props;
  const classes = useHeaderStyle({ expanded, selectedState });
  return (
    <div className={classes.container} onClick={expand}>
      <div style={{ display: "flex" }}>
        <PlayArrowIcon
          fontSize="small"
          className={classes.arrow}
          color="primary"
        />
        <FormLabel component="legend" className={classes.header}>
          {header}
        </FormLabel>
      </div>

      <div style={{ display: "flex" }}>
        {props.onSelectSpecific && (
          <button
            className={classes.selectOneButton}
            onClick={(e) => {
              e.stopPropagation();
              onSelectSpecific();
            }}
          >
            <ArrowRightCircle
              color={primaryColor}
              width="12"
              height="12"
              title={t({
                id: "filter.eintragAuswaehlen",
                message: "Nur diesen Eintrag auswählen",
              })}
            />
          </button>
        )}
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          indeterminate={selectedState === "some"}
          checked={selectedState === "all"}
          onChange={onSelectAll}
          size="small"
          color="primary"
        />
      </div>
    </div>
  );
}

const useHeaderStyle = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    height: 30,
    "&:hover": {
      backgroundColor: "#F8F8F8",
      "& > div > button": { opacity: 1 },
    },
  },
  header: {
    fontWeight: "bolder",
    marginLeft: 10,
    marginBottom: 5,
    marginTop: 5,
    cursor: "pointer",
    "&:hover": {
      color: primaryColor,
    },
  },
  arrow: {
    transform: (props: any) => (props.expanded ? "rotate(90deg)" : "unset"),
  },
  selectOneButton: {
    marginRight: 5,
    padding: 5,
    borderRadius: 100,
    width: 10,
    color: primaryColor,
    opacity: 0,
  },
});

const useStyle = makeStyles({
  container: {
    // padding: 2,
    // borderBottom: "1px solid rgb(240, 240, 240)",
    height: "100%",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkboxElement: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 30,
    marginLeft: (props: any) => (props.expandable ? 30 : 10),
    "&:hover": {
      backgroundColor: "#F8F8F8",
      "& > div > button": { opacity: 1 },
    },
  },
  label: {
    marginLeft: 5,
  },
  header: {
    fontWeight: "bolder",
    marginBottom: 5,
    marginTop: 5,
    color: "#555555",
  },
  selectOneButton: {
    marginRight: 2,
    padding: 5,
    borderRadius: 100,
    color: primaryColor,
    opacity: 0,
  },
});
