import { makeStyles } from "@material-ui/core";
import TrashButton from "components/Buttons/TrashButton";
import { primaryColor } from "theme";
import { t } from "@lingui/macro";

type Props = {
  onRemove: () => void;
};

export default function AuftragsToolbar(props: Props) {
  const classes = useStyles();
  return (
    <div>
      <span className={classes.trash} onClick={() => props.onRemove()}>
        <TrashButton onClick={() => props.onRemove()} />
        <span>
          {t({
            id: "export.listeLoeschen",
            message: "Exportliste löschen",
          })}
        </span>
      </span>
    </div>
  );
}

const useStyles = makeStyles({
  trash: {
    display: "flex",
    alignItems: "center",
    color: primaryColor,
    textDecoration: "underline",
    float: "right",
    marginRight: "29px",
    cursor: "pointer",
  },
});
