import { t } from "@lingui/macro";
import { FilterZeitraumGrundlage } from "types/FilterZeitraum";

export type ZeitraumGrundlage = {
  key: FilterZeitraumGrundlage;
  enumValue: string;
  value: string;
};

export function getZeitraumGrundlage(): ZeitraumGrundlage[] {
  const l: ZeitraumGrundlage[] = [
    {
      key: "ERSCHIENEN",
      enumValue: "Erschienen",
      value: t({ id: "page.filterZeitraum.erschienen" }),
    },
    {
      key: "GEFUNDEN",
      enumValue: "Gefunden",
      value: t({ id: "page.filterZeitraum.gefunden" }),
    },
  ];

  return l;
}
