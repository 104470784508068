import { t } from "@lingui/macro";
import { MeldungView } from "types/MeldungView";

export type IconId =
  | "like"
  | "dislike"
  | "share"
  | "comment"
  | "share"
  | "eye"
  | "email"
  | "webseite";

export type InfoDict = Array<{
  key: string;
  value: any;
  iconId?: IconId;
}>;

export function getMeldungsInfo(meldung: MeldungView): InfoDict {
  const infoDict: InfoDict = [
    {
      key: t({ id: "meldungsInfo.autor", message: "Autor" }),
      value: meldung.autor?.autorinformation,
    },
    {
      key: t({ id: "meldungsInfo.medium", message: "Medium" }),
      value: meldung.medium?.name ?? meldung.quelle?.quelleInformation,
    },
    {
      key: t({ id: "meldungsInfo.medienart", message: "Medienart" }),
      value: meldung.medienart.nameMitGattung ?? meldung.medienart.name,
    },
    {
      key: t({ id: "meldungsInfo.mediengattung", message: "Mediengattung" }),
      value: meldung.mediengattung.name,
    },
    {
      key: t({ id: "meldungsInfo.reichweite", message: "Reichweite" }),
      value: meldung.reichweite ?? meldung.medium?.reichweite,
    },
    {
      key: t({ id: "meldungsInfo.visits", message: "Visits" }),
      value: meldung.medium?.onlineVisits,
    },
    {
      key: t({
        id: "meldungsInfo.pageImpressions",
        message: "Page Impressions",
      }),
      value: meldung.medium?.onlinePageImpressions,
    },

    {
      key: t({ id: "meldungsInfo.auflageInformation", message: "Auflage" }),
      value: meldung.medium?.auflageinformation,
    },

    {
      key: t({ id: "meldungsInfo.seitename", message: "Seite" }),
      value: meldung.printInformationen?.seitename,
    },
    {
      key: t({ id: "meldungsInfo.farbigkeit", message: "Farbigkeit" }),
      value: meldung.printInformationen?.farbigkeitinformation,
    },
    {
      key: t({ id: "meldungsInfo.groesse", message: "Größe" }),
      value: meldung.printInformationen?.groesseinformation,
    },
    {
      key: t({ id: "meldungsInfo.platzierung", message: "Platzierung" }),
      value: meldung.printInformationen?.platzierunginformation,
    },

    {
      key: t({ id: "meldungsInfo.aaew", message: "Anzeigenäquivalenz" }),
      value: meldung.anzeigenAequivalenzWert,
    },

    {
      key: t({ id: "meldungsInfo.erschienen", message: "Erschienen" }),
      value: meldung.veroeffentlicht,
    },
    {
      key: t({ id: "meldungsInfo.gefunden", message: "Gefunden" }),
      value: meldung.empfangen,
    },

    {
      key: t({
        id: "meldungsInfo.printartikelinformation",
        message: "Artikel Information",
      }),
      value: meldung.printartikelinformation,
    },
  ];
  return infoDict.filter((i) => i.value && i.value !== "");
}

export function getMeldungsWeitereInfos(meldung: MeldungView): InfoDict {
  const infoDict: InfoDict = [
    {
      key: t({ id: "meldungsInfo.webseite", message: "Webseite" }),
      value: meldung.medium?.webseiteinformation,
      iconId: "webseite",
    },
    {
      key: t({ id: "meldungsInfo.verlag", message: "Verlag" }),
      value: meldung.medium?.verlaginformationen,
    },
    {
      key: t({ id: "meldungsInfo.redaktion", message: "Redaktion" }),
      value: meldung.medium?.redaktioninformation,
      iconId: "email",
    },
    {
      key: t({ id: "meldungsInfo.regionalitaet", message: "Regionalität" }),
      value: meldung.medium?.regionalitaetinformation,
    },
    {
      key: t({ id: "meldungsInfo.sprache", message: "Sprache" }),
      value: meldung.sprache.name,
    },
    {
      key: t({ id: "meldungsInfo.herkunft", message: "Herkunft" }),
      value: meldung.herkunftsland.name,
    },
  ];

  return infoDict.filter((i) => i.value && i.value !== "");
}

export function getMeldungsEngagement(meldung: MeldungView): InfoDict {
  const infoDict: InfoDict = [
    {
      key: t({
        id: "meldungsInfo.engagement.anzahlComments",
        message: "Kommentare",
      }),
      value: meldung.engagements?.anzahlComments,
      iconId: "comment",
    },
    {
      key: t({ id: "meldungsInfo.engagement.reactions", message: "Reactions" }),
      value: meldung.engagements?.anzahlReactions,
      iconId: null,
    },
    {
      key: t({ id: "meldungsInfo.engagement.likes", message: "Likes" }),
      value: meldung.engagements?.anzahlLikes,
      iconId: "like",
    },
    {
      key: t({ id: "meldungsInfo.engagement.dislikes", message: "Dislikes" }),
      value: meldung.engagements?.anzahlDislikes,
      iconId: "dislike",
    },
    {
      key: t({ id: "meldungsInfo.engagement.shares", message: "Shares" }),
      value: meldung.engagements?.anzahlShares,
      iconId: "share",
    },
    {
      key: t({
        id: "meldungsInfo.engagement.videoViews",
        message: "Videoviews",
      }),
      value: meldung.engagements?.anzahlVideoViews,
      iconId: "eye",
    },
  ];
  return infoDict.filter((i) => i.value && i.value !== "");
}
