import { makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

type Props = {
  count: number;
  headline: string;
};

export default function HashtagsSkelton(props: Props) {
  const classes = useStyle();

  let hashtags = [];
  for (let index = 0; index < props.count; index++) {
    hashtags.push("");
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.headline}>{props.headline}</Typography>
      <br />
      {hashtags.map(() => (
        <span className={classes.hashtag}>
          <Skeleton className={classes.hashtagContent} />
        </span>
      ))}
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    borderBottom: "1px solid lightGray",
    marginRight: 10,
    paddingBottom: 10,
  },
  headline: {
    color: "#00C1BE",
    textTransform: "uppercase",
    marginBottom: 5,
  },
  dict: {
    fontSize: 10,
  },
  hashtag: {
    display: "inline-flex",
    border: "none",
    borderRadius: 5,
    margin: "1px 2px",
    padding: "0px 5px",
    lineHeight: 1.75,
    height: 21,
    color: "inherit",
    // backgroundColor: "rgb(242, 242, 242)",
    overflow: "hidden",
    WebkitBoxAlign: "center",
    alignItems: "center",
    position: "relative",
  },
  hashtagContent: {
    width: "100px",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // alignSelf: "center",
  },
});
