import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import AppBar from "components/AppBar";
import { useParams } from "react-router-dom";
import { MeldungView } from "types/MeldungView";
import { getMeldungByIdGQL } from "requests/meldungenRequests";
import MeldungsDetailPaper from "components/MeldungsDetailPaper";
import { useAppSelector } from "redux/store";
import { selectFilterState } from "redux/filter";
import MeldungsDetailSkeleton from "components/MeldungsDetailPaper/MeldungsDetailSkeleton";

interface ParamType {
  meldungDetailId: string;
  sessionId: string;
}

export default function Meldung() {
  const { meldungDetailId } = useParams<ParamType>();
  const { suche } = useAppSelector(selectFilterState);

  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStyle();
  const [meldung, setMeldung] = useState<MeldungView>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setLoading(true);
    if (meldungDetailId)
      getMeldungByIdGQL(meldungDetailId, suche)
        .then((r) => setMeldung(r))
        .catch((e) => setError(e.toString()))
        .finally(() => setLoading(false));
  }, [meldungDetailId, suche]);

  if (error) return <div>{error}</div>;

  return (
    <div className={classes.container}>
      <>
        <AppBar />
        <div className={classes.seperator}></div>

        <div className={classes.paperContainer}>
          {loading ? (
            <MeldungsDetailSkeleton />
          ) : (
            <MeldungsDetailPaper
              meldung={meldung}
              meldungDetailUrl={meldungDetailId}
            />
          )}
        </div>
      </>
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    minHeight: "100%",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "15px",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  paperContainer: {
    paddingRight: "5%",
    paddingLeft: "5%",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    minHeight: "90vh",
    borderRadius: 10,
  },
  paperHeader: {
    margin: 10,
    borderBottom: "1px solid lightGray",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    fontWeight: "bold",
    alignItems: "center",
  },
  navigatorContainer: {
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
  },
  actionsContainer: {
    minWidth: "10%",
  },
  seperator: {
    marginTop: 20,
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gap: 20,
    padding: 20,
  },
  headline: {
    fontWeight: "bold",
  },
});
