import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Container } from "types/Container";
import { Zeitangabe } from "types/Zeitangabe";
import { getContainerGQL, getExportListeGQL } from "requests/meldungenRequests";
import { RootState } from "./store";
import { LocaleTag } from "types/I18n";

export type ContainerStateType = Container & {
  status: "loading" | "idle" | "failed" | "init";
};

export type Ansicht = "kacheln" | "chart"

const initialState: ContainerStateType = {
  auftraege: [],
  exportliste: [],
  medienarten: [],
  zeitangaben: [],
  herkunftslaender: [],
  kontinente: [],
  sprachen: [],
  bedingungen: [],
  modifiers: [],
  suchQueryFelder: [],
  accountName: "",
  status: "init",
  letzterExportierterAuftrag: "",
  toggleAllerMeldungenErlaubt: false,
  toggleMeldungenMaximaleAnzahl: 0,
  ansicht: "kacheln",
};

export const getContainer = createAsyncThunk(
  "container/getContainer",
  async (locale : LocaleTag) => {
    return await getContainerGQL(locale);
  }
);

export const getExportListe = createAsyncThunk(
  "container/getExportListe",
  async (): Promise<string[]> => {
    return await getExportListeGQL();
  }
);

export const containerSlice = createSlice({
  name: "container",
  initialState: initialState,
  reducers: {
    changeAnsicht: (state, action: PayloadAction<Ansicht>) => {
      state.ansicht = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getExportListe.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.exportliste = action.payload;
        }
      )
      .addCase(
        getContainer.fulfilled,
        (state, action: PayloadAction<Container>) => {
          state.auftraege = action.payload.auftraege;
          state.exportliste = action.payload.exportliste;
          state.medienarten = action.payload.medienarten;
          state.sprachen = action.payload.sprachen;
          state.herkunftslaender = action.payload.herkunftslaender;
          state.kontinente = action.payload.kontinente;
          state.bedingungen = action.payload.bedingungen;
          state.modifiers = action.payload.modifiers;
          state.suchQueryFelder = action.payload.suchQueryFelder;
          state.toggleAllerMeldungenErlaubt =
            action.payload.toggleAllerMeldungenErlaubt;
          state.toggleMeldungenMaximaleAnzahl =
            action.payload.toggleMeldungenMaximaleAnzahl;

          const zeitangaben = action.payload.zeitangaben.map((x) => x);
          zeitangaben.push(initalZeitangabeAlles); // alles dazu packen damit der media account filter funktioniert
          zeitangaben.push(initalZeitangabeCustom); // custom dazu packen damit der media account filter funktioniert

          state.zeitangaben = zeitangaben;
          state.accountName = action.payload.accountName;
          state.letzterExportierterAuftrag =
            action.payload.letzterExportierterAuftrag;

          state.status = "idle";
        }
      )
      .addCase(getContainer.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  changeAnsicht
} = containerSlice.actions;

export const selectContainerState = (state: RootState) => state.container;

export default containerSlice.reducer;

// zusätzliche Methoden

export const initalZeitangabeAlles: Zeitangabe = {
  bezeichner: "alles", // key
  name: "", // value
  zeitraum: { von: new Date().toISOString(), bis: new Date().toISOString() },
};

export const initalZeitangabeCustom: Zeitangabe = {
  bezeichner: "custom",
  name: "",
  zeitraum: { von: new Date().toISOString(), bis: new Date().toISOString() },
};
