import { t } from "@lingui/macro";
import { Zeitangabe } from "types/Zeitangabe";

export default function TranslateZeit(array: Zeitangabe[]) {
  const translatedArray = [
    {
      name: t({
        id: "page.filterZeitraum.heute",
        message: "Heute",
      }),
      bezeichner: array[0].bezeichner,
      zeitraum: array[0].zeitraum,
    },
    {
      name: t({
        id: "page.filterZeitraum.gestern",
        message: "Gestern",
      }),
      bezeichner: array[1].bezeichner,
      zeitraum: array[1].zeitraum,
    },
    {
      name: t({
        id: "page.filterZeitraum.letzteDreiTage",
        message: "Letzte 3 Tage",
      }),
      bezeichner: array[2].bezeichner,
      zeitraum: array[2].zeitraum,
    },
    {
      name: t({
        id: "page.filterZeitraum.letzteSiebenTage",
        message: "Letzte 7 Tage",
      }),
      bezeichner: array[3].bezeichner,
      zeitraum: array[3].zeitraum,
    },
    {
      name: t({
        id: "page.filterZeitraum.letzteDreissigTage",
        message: "Letzte 30 Tage",
      }),
      bezeichner: array[4].bezeichner,
      zeitraum: array[4].zeitraum,
    },
    {
      name: "",
      bezeichner: array[5].bezeichner,
      zeitraum: array[5].zeitraum,
    },
    {
      name: "",
      bezeichner: array[6].bezeichner,
      zeitraum: array[6].zeitraum,
    },
  ];

  return translatedArray;
}
