import { makeStyles } from "@material-ui/core";
import { TrashSvg } from "icons/TrashIcon";
import clsx from "clsx";
import { primaryColor } from "theme";

type TrashButtonProps = {
  className?: string;
  onClick: () => void;
};

export default function TrashButton(props: TrashButtonProps) {
  const classes = useStyles();
  const classesRoot = clsx(classes.root, props.className ?? "");

  return (
    <div onClick={() => props.onClick()} className={classesRoot}>
      <TrashSvg />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    width: "24px",
    height: "24px",
    fill: primaryColor,
    cursor: "pointer",
  },
});
