import React, { useEffect, useState } from "react";
import { i18n, I18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { LocaleContextType, LocaleTag } from "types/I18n";
import getCatalogueByLocaleTag from "./catalogues";
import { createNumberParser } from "lib/numberFormats";

export const LocaleContext = React.createContext<LocaleContextType>({
  localeContext: "de-DE",
  parseNumber: createNumberParser("de-DE"),
  setLocaleContext: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function I18nContextProvider(props: Props) {
  const [localeTag, setLocaleTag] = useState<LocaleTag>("de-DE");
  const [i18n, setI18n] = useState<I18n>(initLocale(localeTag));

  useEffect(() => {
    if (localeTag !== i18n.locale) {
      const locale = initLocale(localeTag);
      setI18n(locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeTag]);

  return (
    <LocaleContext.Provider
      value={{
        localeContext: localeTag,
        setLocaleContext: setLocaleTag,
        parseNumber: createNumberParser(localeTag),
      }}
    >
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={true}>
        {props.children}
      </I18nProvider>
    </LocaleContext.Provider>
  );
}

const initLocale = (localeTag: LocaleTag): I18n => {
  const catalogue = getCatalogueByLocaleTag(localeTag);
  i18n.load(localeTag, catalogue.messages);
  i18n.activate(localeTag);
  return i18n;
};
