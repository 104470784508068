import { makeStyles } from "@material-ui/core";
import theme from "theme";
import Navigation from "./Navigation";
import Sprachen from "./Sprachen";
import NavigationLeft from "./NavigationLeft";

export default function AppBar() {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <NavigationLeft />
      <Sprachen />
      <div className={classes.separator} />
      <Navigation />
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  popoverContainer: {
    background: "white",
    padding: 10,
  },
  logoContainer: {
    width: 95,
    height: 45,
  },
  navigationButton: {
    color: "rgb(62, 62, 62)",
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      color: "#00C1BE",
    },
  },
  separator: {
    borderLeft: "1px solid lightgray",
    alignSelf: "stretch",
    margin: "5px 0.5rem",
    marginLeft: 10,
    marginRight: 10,
  },
});
