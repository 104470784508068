export function getMeldungsDetailUrl(meldungsId: string) {
  return `/search/meldungen/${meldungsId}`;
}

export function getMeldungenUrl() {
  return `/search/meldungen`;
}

export function getLandingUrl() {
  return `/start`;
}

export function getMediaAccountUrl() {
  return "https://www.media-account.de";
}
