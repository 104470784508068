import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MuiThemeProvider } from "@material-ui/core";
import "ma-uicomponents/dist/app.css";
import { getTheme } from "themes/themes";

ReactDOM.render(
  // <React.StrictMode>
  <MuiThemeProvider theme={getTheme()}>
    <App />
  </MuiThemeProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
