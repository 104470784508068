import { t } from "@lingui/macro";
import { makeStyles } from "@material-ui/styles";
import AppBar from "components/AppBar";
import EinfacheSuchleiste from "components/EinfacheSuchleiste";
import Seperator from "components/Seperator";

export default function Landing() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <AppBar />
      <Seperator />

      <div className={classes.centerContainer}>
        <div className={classes.header}>
          {t({ id: "title", message: "Explore" })}
        </div>
        <div className={classes.text}>
          {t({
            id: "landingpage.text",
            message:
              "Entdecken Sie Meldungen außerhalb Ihrer aktuellen Suchaufträge und importieren Sie diese kostenlos in Ihren MediaAccount.",
          })}
        </div>

        <div className={classes.search}>
          <EinfacheSuchleiste />
        </div>

        <div className={classes.screenshot}>
          <img src="/landing.png" alt="Landing" />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "15px",
  },
  centerContainer: {
    marginTop: "100px",
    width: "100%",
    textAlign: "center",
  },
  header: {
    fontWeight: "bolder",
    fontSize: 20,
    marginBottom: "10px",
  },
  text: {
    maxWidth: "400px",
    overflow: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  search: {
    marginTop: "20px",
    marginBottom: "20px",
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  screenshot: {},
});
