import { DateTimeFormatOptions } from "intl";
import { LocaleTag } from "types/I18n";
import {
  parseJSON,
  isSameYear,
  isThisYear,
  isSameMonth,
  isSameDay,
  startOfYear,
  endOfYear,
} from "date-fns";

// Der Zeitbereich soll immer möglichst kurz dargestellt werden.
export default function formatDateRange(
  locale: LocaleTag,
  vonInput: Date | string,
  bisInput: Date | string
) {
  const vonOptions: DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const bisOptions: DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const von = parseJSON(vonInput);
  const bis = parseJSON(bisInput);

  if (isSameYear(von, bis)) {
    vonOptions.year = undefined;
    if (isSameMonth(von, bis)) vonOptions.month = undefined;
    if (isThisYear(bis)) bisOptions.year = undefined;

    // das ganze jahr
    if (isSameDay(startOfYear(von), von) && isSameDay(endOfYear(bis), bis)) {
      bisOptions.year = "numeric";
      bisOptions.day = undefined;
      bisOptions.month = undefined;
      return bis.toLocaleDateString(locale, bisOptions);
    }
  }

  if (isSameDay(von, bis)) return bis.toLocaleDateString(locale, bisOptions);

  return `${von.toLocaleDateString(
    locale,
    vonOptions
  )} - ${bis.toLocaleDateString(locale, bisOptions)}`;
}
