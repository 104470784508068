import { makeStyles } from "@material-ui/core";

export default function Seperator() {
  const classes = useStyle();

  return <div className={classes.seperator}></div>;
}

const useStyle = makeStyles({
  seperator: {
    marginTop: 20,
  },
});
