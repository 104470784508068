import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { MeldungView } from "types/MeldungView";
import TrashButton from "components/Buttons/TrashButton";
import { useHistory } from "react-router";
import { getMeldungsDetailUrl } from "lib/urls";

type ListenItemProps = {
  meldung: MeldungView;

  onRemove: (meldung: MeldungView) => void;
};

export default function ListenItem(props: ListenItemProps) {
  const classes = useStyles();
  const history = useHistory();

  const meldung = props.meldung;

  return (
    <Paper className={classes.paperStyle}>
      <Grid
        className={classes.grid}
        direction="row"
        container
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <Grid>
          <TrashButton onClick={() => props.onRemove(meldung)} />
        </Grid>

        <Grid
          className={classes.textStyle}
          onClick={() => {
            history.push(getMeldungsDetailUrl(meldung.meldungDetailUrl));
          }}
        >
          <Typography variant="caption" display="block">
            {meldung.veroeffentlicht} | {meldung.herkunftsland?.name} |{" "}
            {meldung.quelle?.quelleTitel}
          </Typography>
          <Typography variant="subtitle1" className={classes.headlineStyle}>
            {meldung.previewHeadlineHtml}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
    flexWrap: "nowrap",
  },
  textStyle: {
    marginRight: "10px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  paperStyle: {
    height: "90px",
    margin: "10px",
    marginBottom: "20px",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    boxShadow:
      "rgba(9, 32, 77, 0.12) 0px 3px 8px, rgba(29, 17, 51, 0.12) 0px 0px 2px",
  },
  headlineStyle: {
    color: theme.textHeadlineColor,
    marginTop: 5,
    fontWeight: "bold",
    wordBreak: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    lineHeight: "20px",
    maxHeight: "40px",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    whiteSpace: "normal",
  },
  root: {
    margin: "10px",
  },
}));
