import { t } from "@lingui/macro";
import { makeStyles } from "@material-ui/core";
import Button from "components/Buttons/Button";

type ListenHeaderProps = {
  meldungsCount: number;

  onCancel: () => void;
  onExport: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function ListenHeader(props: ListenHeaderProps) {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <span className={classes.header}>
        {props.meldungsCount}{" "}
        {t({
          id: "listenimport.header.export.text",
          message: `Meldungen exportieren`,
        })}
      </span>

      <span className={classes.buttons}>
        <Button
          className={classes.buttonCancel}
          onClick={props.onCancel}
          text={t({
            id: "listenimport.header.cancel.button",
            message: "Abbrechen",
          })}
          variant="secondary"
        />
        <Button
          className={classes.buttonExport}
          onClick={(e) => {
            props.onExport(e);
          }}
          text={t({
            id: "listenimport.header.export.button",
            message: "Exportieren",
          })}
          variant="primary"
        />
      </span>
    </span>
  );
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    alignItems: "center",
  },

  buttonExport: {
    marginRight: "10px",
  },
  buttonCancel: {
    marginRight: "10px",
  },
  header: {
    color: "#3E3E3E",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    marginLeft: "10px",
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",

    height: "75px",

    marginRight: "10px",
    borderBottom: "1px solid lightGray",
    paddingBottom: "10px",
  },
}));
