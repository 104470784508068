import { t, Trans } from "@lingui/macro";
import { makeStyles, Paper } from "@material-ui/core";
import { CalendarIcon } from "icons/CalendarIcon";
import { LocaleContext } from "locales";
import { useContext, useEffect, useState } from "react";
import { initalZeitangabeCustom, selectContainerState } from "redux/container";
import {
  changeFilter,
  getInitialZeitangabe,
  selectFilterState,
} from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import classNames from "classnames";
import {
  getZeitraumGrundlage,
  ZeitraumGrundlage,
} from "redux/zeitraumGrundlagen";
import { FilterZeitraumGrundlage, IsoDateString } from "types/FilterZeitraum";
import { LocaleTag } from "types/I18n";
import { Zeitangabe } from "types/Zeitangabe";
import FilterChip from "./FilterChip";
import formatDateRange from "lib/FormatDateRange";
import { parseJSON } from "date-fns";
import { currentCalendar } from "lib/intlCalendar";
import { Datepicker } from "ma-uicomponents";
import { primaryColor, secondaryColor } from "theme";
import clsx from "clsx";
import TranslateZeit from "components/ChipTranslations/ZeitChipTrans";

type ChangedProps = {
  zeitpunktVon?: IsoDateString | undefined;
  zeitpunktBis?: IsoDateString | undefined;
  zeitangabe?: Zeitangabe | undefined;
  zeitraumGrundlage?: FilterZeitraumGrundlage | undefined;
};

type ZeitChipProps = ChangedProps & {
  zeitangaben: Zeitangabe[];
  zeitraumGrundlagen: ZeitraumGrundlage[];

  localeTag: LocaleTag;
};

type PopoverProps = ZeitChipProps & {
  onChange: (filter: ChangedProps) => void;
};

export default function ZeitChip() {
  const filter = useAppSelector(selectFilterState);
  const container = useAppSelector(selectContainerState);
  const { localeContext } = useContext(LocaleContext);

  const dispatch = useAppDispatch();

  const [chipProps, setChipProps] = useState<ZeitChipProps>({
    localeTag: localeContext,
    zeitangaben: container.zeitangaben,
    zeitraumGrundlagen: getZeitraumGrundlage(),
  });

  useEffect(() => {
    setChipProps({
      zeitangabe: filter.zeitangabe,
      zeitraumGrundlage: filter.zeitraumGrundlage,
      zeitpunktVon: filter.zeitpunktVon,
      zeitpunktBis: filter.zeitpunktBis,

      localeTag: localeContext,

      zeitangaben: TranslateZeit(container.zeitangaben),
      zeitraumGrundlagen: getZeitraumGrundlage(),
    });
  }, [filter, container, localeContext]);

  const handleChanged = (value: ChangedProps) => {
    dispatch(
      changeFilter({
        ...filter,
        zeitpunktVon: value.zeitpunktVon,
        zeitpunktBis: value.zeitpunktBis,
        zeitangabe: value.zeitangabe,
        zeitraumGrundlage: value.zeitraumGrundlage,
      })
    );
  };

  return (
    <FilterChip
      xButtonBehaviour={() =>
        dispatch(
          changeFilter({
            ...filter,
            zeitangabe: getInitialZeitangabe(container.zeitangaben),
          })
        )
      }
      TitleComponent={Title}
      titleProps={{ ...chipProps }}
      PopoverContentComponent={Popover}
      popoverProps={{
        ...chipProps,
        onChange: handleChanged,
      }}
    />
  );
}

function Title(props: ZeitChipProps) {
  const renderGrundlage = () => {
    if (props.zeitangabe?.bezeichner === "alles") return null;

    const text = props.zeitraumGrundlagen.find(
      (g) => g.key === props.zeitraumGrundlage
    )?.value;
    if (!text) return null;
    return `${text}: `;
  };

  const renderZeitraum = () => {
    if (props.zeitangabe?.bezeichner === "alles")
      return (
        <Trans id="zeitraum.pageFilterZeitraumAuswaehlen">
          Zeitraum auswählen
        </Trans>
      );
    if (props.zeitangabe?.bezeichner === "custom") {
      if (!props.zeitpunktVon || !props.zeitpunktBis) return "-";
      return formatDateRange(
        props.localeTag,
        props.zeitpunktVon,
        props.zeitpunktBis
      );
    }

    return props.zeitangabe
      ? props.zeitangaben.find(
          (x) => x.bezeichner === props.zeitangabe.bezeichner
        )?.name ?? "-"
      : "-";
  };

  return (
    <div style={{ display: "flex" }}>
      <CalendarIcon
        style={{ paddingRight: "5px" }}
        // size="18"
        color={secondaryColor}
        height="15px"
        width="25px"
        title={t({ id: "zeitraum.pageFilterZeitraumAuswaehlen" })}
      />
      {renderGrundlage()}
      {renderZeitraum()}
    </div>
  );
}

function Popover(props: PopoverProps) {
  const [von, setVon] = useState(parseJSON(props.zeitpunktVon));
  const [bis, setBis] = useState(parseJSON(props.zeitpunktBis));
  const [vonError, setVonError] = useState<boolean>(false);
  const [bisError, setBisError] = useState<boolean>(false);
  const classes = useStyle();

  return (
    <Paper className={classes.paper} elevation={1}>
      <div className={classes.column1}>
        <section>
          <div className={classes.h3}>
            <Trans id="zeitraum.relativerZeitraum">relativer Zeitraum</Trans>
          </div>
          <div>
            {props.zeitangaben
              .filter((z) => z.bezeichner !== "custom")
              .map((z) => (
                <div
                  className={
                    z.bezeichner === props.zeitangabe.bezeichner
                      ? classes.zeitraumAktiv
                      : classes.zeitraum
                  }
                  key={z.bezeichner}
                  onClick={() => {
                    props.onChange({
                      ...props,
                      zeitangabe: z,
                      zeitpunktVon: z.zeitraum?.von,
                      zeitpunktBis: z.zeitraum?.bis,
                    });
                  }}
                >
                  {z.name}
                </div>
              ))}
          </div>
        </section>
      </div>
      <div className={classes.column2}>
        <section>
          <div className={classes.h3}>
            <Trans id="zeitraum.pageFilterZeitraumCustom">
              benutzerdefinierter Zeitraum
            </Trans>
          </div>
          <div className={classNames(classes.datum, { error: vonError })}>
            <span className={clsx(classes.datumTitle, classes.grundlageSpan)}>
              <Trans id="zeitraum.vonDatum">Von</Trans>
            </span>
            <Datepicker
              top={10}
              placeholder={<Trans id="zeitraum.datumAuswaehlen">Auswahl</Trans>}
              calendar={currentCalendar(props.localeTag)}
              date={props.zeitpunktVon ? parseJSON(props.zeitpunktVon) : von}
              align="right"
              onChange={(d: string) => {
                const date = parseJSON(d);
                setVon(date);
                if (date <= bis) {
                  props.onChange({
                    ...props,
                    zeitangabe: initalZeitangabeCustom,
                    zeitpunktVon: date.toISOString(),
                    zeitpunktBis: bis.toISOString(),
                  });
                  setVonError(false);
                  setBisError(false);
                } else {
                  setVonError(true);
                  setBisError(false);
                }
              }}
            />
          </div>
          <div className={classNames(classes.datum, { error: bisError })}>
            <span className={clsx(classes.datumTitle, classes.grundlageSpan)}>
              <Trans id="zeitraum.bisDatum">Bis</Trans>
            </span>
            <Datepicker
              placeholder={<Trans id="zeitraum.datumAuswaehlen">Auswahl</Trans>}
              calendar={currentCalendar(props.localeTag)}
              date={props.zeitpunktBis ? parseJSON(props.zeitpunktBis) : bis}
              align="right"
              onChange={(d: string) => {
                const date = parseJSON(d);
                setBis(date);
                if (date >= von) {
                  props.onChange({
                    ...props,
                    zeitpunktVon: von.toISOString(),
                    zeitpunktBis: date.toISOString(),
                    zeitangabe: initalZeitangabeCustom, // war früher custom
                  });
                  setBisError(false);
                  setVonError(false);
                } else {
                  setBisError(true);
                  setVonError(false);
                }
              }}
            />
          </div>
        </section>
        <section>
          <div className={classes.h3}>
            <Trans id="zeitraum.betrachtungszeitpunkt">Zeitpunkt</Trans>
          </div>
          <div className={classes.zeitpunktContainer}>
            {props.zeitraumGrundlagen.map((option) => (
              <div
                className={classes.zeitpunkt}
                key={option.key}
                onClick={() =>
                  props.onChange({
                    ...props,
                    zeitraumGrundlage: option.key as FilterZeitraumGrundlage,
                  })
                }
              >
                <input
                  className={classes.grundlageInput}
                  type="radio"
                  key={option.key}
                  checked={option.key === props.zeitraumGrundlage}
                  onChange={() =>
                    props.onChange({
                      ...props,
                      zeitraumGrundlage: option.key as FilterZeitraumGrundlage,
                    })
                  }
                />
                {option.value}
              </div>
            ))}
          </div>
        </section>
      </div>
    </Paper>
  );
}

const useStyle = makeStyles({
  paper: {
    display: "flex",
    flexFlow: "row wrap",
    padding: "2.5em 1.5em 3em 1.5em",
    minWidth: "350px",
    minHeight: "325px",
    color: "#333",
  },

  h3: {
    padding: "4px 5px",
    fontSize: "13px",
    fontWeight: "bold",
    marginBottom: "5px",
  },

  column1: {
    paddingRight: "1em",
    width: "50%",
  },
  column2: {
    width: "50%",
    paddingLeft: "1em",
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
  },

  datumTitle: {
    minWidth: "45px",
  },

  grundlageSpan: {
    marginRight: "1em",
  },

  grundlageInput: {
    marginRight: " 10px",
  },

  zeitpunktContainer: {
    padding: "1em",
    background: "#f2f2f2",
    flexFlow: "column wrap",
  },

  zeitpunkt: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    cursor: "pointer",
    padding: "4px 5px",
    transition: "background 0.15s ease-in",
    "&:hover": {
      background: "#e2e2e2",
    },
  },

  zeitraum: {
    padding: "4px 5px",
    cursor: "pointer",
    userSelect: "none",
    transition: "background 0.15s ease-in",

    "&:hover": {
      background: "#f2f2f2",
    },
  },

  zeitraumAktiv: {
    color: primaryColor,
    fontWeight: "bold",

    padding: "4px 5px",
    cursor: "pointer",
    userSelect: "none",
    transition: "background 0.15s ease-in",

    "&:hover": {
      background: "#f2f2f2",
    },
  },

  datum: {
    padding: "4px 0 5px 4px",
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",

    "&.error .DayPickerInput": {
      "& input, ": {
        border: "1px solid red",
      },
      "&.input:focus": {
        border: "1px solid red",
      },
    },

    "& .DayPickerInput": {
      display: "flex",
      flex: 1,
      position: "relative",

      "> div": {
        position: "absolute",
        top: "23px",
        right: 0,
        left: "auto",
        zIndex: "100",
      },
      "& input": {
        // border: "none",
        borderRadius: "0",
        background: "#f2f2f2",
        border: "1px solid #f2f2f2",
        padding: "4px 5px",
        width: "100%",
        transition: "border-color 0.15s ease-in",
        "&:focus": {
          borderColor: primaryColor,
        },
      },
    },
  },
  // error: {
  //   "& input": {
  //     border: "1px solid red !important",
  //     borderColor: "red",
  //   },
  //   "& input:focus": {
  //     border: "1px solid red !important",
  //     borderColor: "red",
  //   },
  // },
});
