import { Button as ExtButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  disabledColor,
  disabledHoverColor,
  grayColor,
  primaryColor,
  primaryHoverColor,
  secondaryColor,
} from "theme";

type ButtonProps = {
  text: string;

  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;

  className?: string;

  disabled?: boolean;

  variant: "primary" | "secondary";
};

export default function Button(props: ButtonProps) {
  const classes = useStyles();
  let variant =
    props.variant === "primary"
      ? classes.primary
      : props.variant === "secondary"
      ? classes.secondary
      : "primary"; // default ist primary

  if (props.disabled) variant = classes.disabled;

  const classesRoot = clsx(classes.root, props.className ?? "", variant);

  return (
    <ExtButton
      className={classesRoot}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </ExtButton>
  );
}

const useStyles = makeStyles({
  root: {
    borderRadius: "8px",
    fontSize: "14px",
    textTransform: "none",
    paddingBottom: "4px",
    paddingTop: "4px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },

  primary: {
    backgroundColor: primaryColor,
    color: secondaryColor,
    "&:hover": {
      backgroundColor: primaryHoverColor,
    },
  },

  secondary: {
    backgroundColor: disabledColor,
    color: grayColor,
    "&:hover": {
      backgroundColor: disabledHoverColor,
    },
  },

  disabled: {
    backgroundColor: "transparent",
    // borderColor: disabledColor,
    // borderWidth: "2px",
  },
});
