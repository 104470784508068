import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FilterZeitraumGrundlage,
  IsoDateString,
} from "types/FilterZeitraum";
import { RootState } from "./store";
import { MeldungsFilterMengenFragment } from "types/Filter";
import { Zeitangabe } from "types/Zeitangabe";
import { Medienart } from "types/stammdaten/Medienart";
import { initalZeitangabeAlles, initalZeitangabeCustom } from "./container";
import { Suchfeld } from "types/query/Suchfeld";
import { Sprache } from "types/stammdaten/Sprache";
import { Herkunftsland } from "types/stammdaten/Herkunftsland";
import { getSearchQueryGQL } from "requests/filterRequests";

export type FilterState = {
  suche: string | undefined;
  medienarten: string[];
  sprachen: string[];
  herkunftslaender: string[];
  zeitangabe: Zeitangabe | undefined;
  zeitraumGrundlage: FilterZeitraumGrundlage;
  /* Wird auf undefined gesetzt wenn der Filter über en FilterZeitraum abgebildet wird */
  zeitpunktVon: IsoDateString | undefined;
  /* Wird auf undefined gesetzt wenn der Filter über en FilterZeitraum abgebildet wird */
  zeitpunktBis: IsoDateString | undefined;
  suchFelder: Suchfeld[];

  /* Wenn von Query geladen wird darf kein filterReset durchgeführt werden */
  loadLocation: "none" | "query";
};

export type FilterMengen = {
  readonly medienarten: Map<string | null, number>;
  readonly sprachen: Map<string | null, number>;
  readonly herkunftslaender: Map<string | null, number>;

  // Hier weitere definitionen eintragen damit diese im Filer sichtbar sind
};

export function reduceMengen(
  mengen: MeldungsFilterMengenFragment | undefined
): FilterMengen {
  if (mengen === undefined)
    return {
      medienarten: new Map<string | null, number>(),
      sprachen: new Map<string | null, number>(),
      herkunftslaender: new Map<string | null, number>(),
      // Hier weitere definitionen eintragen damit das in den Filter angezeigt wird
    };

  return {
    medienarten: new Map<string | null, number>(
      mengen.medienarten.map((item) => [item.key, item.anzahl])
    ),
    sprachen: new Map<string | null, number>(
      mengen.sprachen.map((item) => [item.key, item.anzahl])
    ),
    herkunftslaender: new Map<string | null, number>(
      mengen.herkunftslaender.map((item) => [item.key, item.anzahl])
    ),
    // Hier weitere definitionen eintragen damit das in den Filter angezeigt wird
  };
}

const initialState: FilterState = {
  suche: "",
  medienarten: [],
  sprachen: [],
  herkunftslaender: [],
  zeitangabe: initalZeitangabeAlles,
  zeitraumGrundlage: "ERSCHIENEN",
  zeitpunktBis: undefined,
  zeitpunktVon: undefined,
  suchFelder: [],
  loadLocation: "none",
};

export function getInitialZeitangabe(zeitangaben: Zeitangabe[]): Zeitangabe {
  return zeitangaben.find((x) => x.bezeichner === "heute");
}

function getInitialMedienarten(medienarten: Medienart[]): string[] {
  // return medienarten.filter((x) => x.standardInSuche).map((x) => x.bezeichner);
  return medienarten.map((x) => x.bezeichner);
}

function getInitialHerkunftslaender(
  herkunftslaender: Herkunftsland[]
): string[] {
  // return medienarten.filter((x) => x.standardInSuche).map((x) => x.bezeichner);
  return herkunftslaender.map((x) => x.bezeichner);
}

function getInitialSprachen(sprachen: Sprache[]): string[] {
  // return medienarten.filter((x) => x.standardInSuche).map((x) => x.bezeichner);
  return sprachen.map((x) => x.bezeichner);
}

export const getSearchQuery = createAsyncThunk(
  "filter/getSearchQueryBySearchId",
  async (searchId: string) => {
    const data = await getSearchQueryGQL(searchId);
    return data;
  }
);

export const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    resetFilter: (
      state,
      action: PayloadAction<{
        zeitangaben: Zeitangabe[];
        medienarten: Medienart[];
        sprachen: Sprache[];
        herkunftslaender: Herkunftsland[];
      }>
    ) => {
      const internInitialeState = {
        ...initialState,
        zeitangabe: getInitialZeitangabe(action.payload.zeitangaben),
        medienarten: getInitialMedienarten(action.payload.medienarten),
        sprachen: getInitialSprachen(action.payload.sprachen),
        herkunftslaender: getInitialHerkunftslaender(
          action.payload.herkunftslaender
        ),
      };

      if (state.loadLocation === "query") return; // kein zurücksetzen durchführen, da der filter über die Query geladen wurde

      state.suchFelder = internInitialeState.suchFelder;
      state.suche = internInitialeState.suche;
      state.medienarten = internInitialeState.medienarten;
      state.zeitangabe = internInitialeState.zeitangabe;
      state.zeitraumGrundlage = internInitialeState.zeitraumGrundlage;
      state.sprachen = internInitialeState.sprachen;
      state.herkunftslaender = internInitialeState.herkunftslaender;

      state.zeitpunktVon =
        internInitialeState.zeitangabe?.zeitraum?.von ??
        initialState.zeitpunktVon;
      state.zeitpunktBis =
        internInitialeState.zeitangabe?.zeitraum?.bis ??
        initialState.zeitpunktBis;
    },
    setLoadLocation: (state, action: PayloadAction<"none" | "query">) => {
      state.loadLocation = action.payload;
    },
    /* Wenn man den ganzen filter State ändern will */
    changeFilter: (state, action: PayloadAction<Partial<FilterState>>) => {
      const filterState = { ...state, ...action.payload };

      // hier weitere states eintragen, damit man alles von hier aus aktualisieren kann
      state.suche = filterState.suche;
      state.medienarten = filterState.medienarten;
      state.zeitangabe = filterState.zeitangabe;
      state.sprachen = filterState.sprachen;
      state.herkunftslaender = filterState.herkunftslaender;
      state.suchFelder = filterState.suchFelder;

      if (
        filterState.zeitangabe &&
        filterState.zeitangabe.bezeichner !== initalZeitangabeCustom.bezeichner
      ) {
        state.zeitpunktVon =
          filterState.zeitangabe?.zeitraum?.von ?? filterState.zeitpunktVon;
        state.zeitpunktBis =
          filterState.zeitangabe?.zeitraum?.bis ?? filterState.zeitpunktBis;
      } else {
        state.zeitpunktVon = filterState.zeitpunktVon;
        state.zeitpunktBis = filterState.zeitpunktBis;
      }

      state.zeitraumGrundlage = filterState.zeitraumGrundlage;
    },
  },
});

export const { resetFilter, changeFilter, setLoadLocation } =
  filterSlice.actions;
export const selectFilterState = (state: RootState) => state.filter;

export default filterSlice.reducer;
