import { makeStyles, TextField } from "@material-ui/core";
import { useState } from "react";
import { changeFilter, resetFilter, selectFilterState } from "redux/filter";
import { useAppDispatch, useAppSelector } from "redux/store";
import { resolveQueryGQL } from "requests/meldungenRequests";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { getMeldungenUrl } from "lib/urls";
import { selectContainerState } from "redux/container";
import { t } from "@lingui/macro";

export default function Suche() {
  const dispatch = useAppDispatch();
  const classes = useStyle();
  const history = useHistory();

  const filter = useAppSelector(selectFilterState);
  const containerState = useAppSelector(selectContainerState);

  const [currentSearch, setCurrentSearch] = useState<string>("");

  const onKeyDown = async (e: any) => {
    if (e.code === "Backspace" && currentSearch === "") {
      const copy = [...filter.suchFelder];
      copy.pop();

      dispatch(
        changeFilter({
          ...filter,
          suchFelder: copy,
        })
      );
      return;
    }
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      dispatch(
        resetFilter({
          zeitangaben: containerState.zeitangaben,
          medienarten: containerState.medienarten,
          sprachen: containerState.sprachen,
          herkunftslaender: containerState.herkunftslaender,
        })
      );

      if (currentSearch?.trim() === "") return;

      try {
        const neueSuchfelder = await resolveQueryGQL(e.target.value);
        dispatch(
          changeFilter({
            ...filter,
            suchFelder: [...neueSuchfelder],
          })
        );

        history.push(getMeldungenUrl());
      } catch {}
    }
  };

  return (
    <div className={classes.container}>
      <SearchIcon fontSize="small" />
      <TextField
        autoFocus
        className={classes.text}
        value={currentSearch}
        onChange={(e) => setCurrentSearch(() => e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={t({
          id: "begriff.suchzeile",
          message: "Suchen...",
        })}
        fullWidth
      />
    </div>
  );
}

const useStyle = makeStyles({
  container: {
    alignItems: "center",
    display: "inline-flex",
    flex: "auto",
  },
  text: {
    width: "100%",
    margin: "0 0.2em",
    flex: "auto",
    "& .MuiInput-underline:before": {
      borderBottomColor: "transparent", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "transparent", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent", // Solid underline on focus
    },
  },
});
