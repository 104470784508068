import { t, Trans } from "@lingui/macro";
import { Button } from "@material-ui/core";
import { AppLayoutMessage } from "components/Message";
import { primaryColor } from "theme";

type MeldungenEmptyProps = {
  onResetFilter: () => void;
};

export default function MeldungenEmpty(props: MeldungenEmptyProps) {
  return (
    <AppLayoutMessage
      text={t({
        id: "page.kacheln.keineMeldungen",
        message: `Ihre Suche hat keine Meldungen gefunden.`,
      })}
      image="/keine-meldung.png"
    >
      <Button
        variant="contained"
        onClick={props.onResetFilter}
        style={{
          marginBottom: "1em",
          background: primaryColor,
          color: "#FFFFFF",
        }}
      >
        <Trans id="aktion.filterZueruecksetzen">Filter zurücksetzen</Trans>
      </Button>
    </AppLayoutMessage>
  );
}
