import { t } from "@lingui/macro";

export default function TranslateSprachen() {
  return [
    {
      name: t({
        id: "sprachenChips.deu",
        message: "Deutsch",
      }),
      bezeichner: "de",
    },
    {
      name: t({
        id: "sprachenChips.ita",
        message: "Italienisch",
      }),
      bezeichner: "it",
    },
    {
      name: t({
        id: "sprachenChips.fra",
        message: "Französisch",
      }),
      bezeichner: "fr",
    },
  ];
}
