import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { primaryColor } from "themes/theme-debug";
import { Suchfeld } from "types/query/Suchfeld";
import SuchePopover from "./SuchePopover";
import ClearIcon from "@material-ui/icons/Clear";

type Props = {
  bedingung: string;
  modifier: string;
  suchfeld: Suchfeld;
  onChange: (value: any, key: string) => void;
  onToggle: (value: string) => void;
  onDelete: () => void;
  query: string;
  negative: boolean;
};

export default function QueryChip(props: Props) {
  const classes = useStyle({
    negative: props.negative,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);

  const onElementClick = (e) => {
    setAnchorEl(() => e.target);
  };
  const sharedProps = { onClick: (e) => onElementClick(e) };
  return (
    <>
      <div className={classes.container}>
        <div {...sharedProps} className={classes.prefix}>
          {props.bedingung}
        </div>
        <div {...sharedProps} className={classes.prefix}>
          {props.modifier}
        </div>

        <div {...sharedProps} className={classes.query}>
          {props.suchfeld.query}
        </div>
        <div className={classes.x} onClick={() => props.onDelete()}>
          <ClearIcon fontSize="small" />
        </div>
      </div>
      <SuchePopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        onChange={(value, type) => props.onChange(value, type)}
        onToggle={(value) => props.onToggle(value)}
        suchFeld={props.suchfeld}
      />
    </>
  );
}

const useStyle = makeStyles({
  container: {
    height: "100%",
    display: "inline-flex",
    borderRadius: 5,
    marginRight: 5,
    marginBottom: 2,
    marginTop: 2,
    borderCollapse: "separate",
    overflow: "hidden",
    cursor: "pointer",
  },
  prefix: {
    backgroundColor: "#808080",
    color: "white",
    borderRight: "1px solid #909090",
    padding: 7,
  },
  query: {
    backgroundColor: (props: any) =>
      props.negative ? "#e30613" : primaryColor,
    color: "white",
    alignItems: "center",
    padding: 7,
  },
  x: {
    display: "grid",
    justifyContent: "center",
    backgroundColor: (props: any) =>
      props.negative ? "#e30613" : primaryColor,
    color: "white",
    alignItems: "center",
    padding: 7,
    "&:hover": {
      color: "red",
    },
    "& > svg": {
      fontSize: 15,
    },
  },
});
