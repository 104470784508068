import { makeStyles } from "@material-ui/core";
import MainLoading from "components/Loading/MainLoading";
import useAuthentication from "lib/Hooks/useAuthentication";
import { Redirect, useParams } from "react-router";

interface ParamType {
  sessionId: string;
}

type Props = {
  loading: boolean;
  children: JSX.Element;
};

export default function PrivateComponentWrapper({
  children,
}: Props): JSX.Element {
  const classes = useStyles();

  const { sessionId } = useParams<ParamType>();
  const { loading, user } = useAuthentication(sessionId);

  if (loading) {
    return (
      <div className={classes.loader}>
        <MainLoading />
      </div>
    );
  }

  return (
    <>
      {user && user.isAuthenticated ? (
        children
      ) : (
        <Redirect to="/login" strict push />
      )}
    </>
  );
}

const useStyles = makeStyles({
  loader: {
    background: "white",
    minHeight: "calc(100vh)",
    overflow: "hidden",
    paddingBottom: 0,
  },
});
