import { makeStyles } from "@material-ui/core";
import loader from "./loading.gif";

export default function MainLoading() {
  const classes = useStyles();
  return (
    <div className={classes.loadingContainer}>
      <img src={loader} alt={"logo"} className={classes.imageContainer} />
    </div>
  );
}

const useStyles = makeStyles({
  loadingContainer: {
    backgroundColor: "white",
    alignItems: "center",
    textAlign: "center",
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  imageContainer: { alignSelf: "center", marginTop: "20%" },
});
