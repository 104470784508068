import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import env from "env";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { Paper, Typography } from "@material-ui/core";
import AppBar from "components/AppBar";

interface ParamType {
  markdownPath: string;
}

const failedText = `*Fehler beim Laden der Dokumentation*`;
const standardFile = `Overview.md`;

export default function Documentation() {
  const [hilfeText, setHilfetext] = useState("");
  const classes = useStyle();

  let { markdownPath } = useParams<ParamType>();

  if (!markdownPath) markdownPath = standardFile;

  useEffect(() => {
    fetch(`${env.endpointSearch}Docs/${markdownPath}`)
      .then((t) => t.text())
      .then((t) => setHilfetext(t))
      .catch((t) => setHilfetext(failedText));
  }, [markdownPath]);

  return (
    <div className={classes.container}>
      <AppBar />
      <div className={classes.seperator}>
        <div className={classes.paperContainer}>
          <Paper elevation={1} className={classes.paper}>
            <div className={classes.paperHeader}>
              <Typography variant="h5" display="block">
                Dokumentation
              </Typography>
            </div>
            <ReactMarkdown
              children={hilfeText}
              className={classes.content}
              remarkPlugins={[gfm]}
            ></ReactMarkdown>
          </Paper>
        </div>
      </div>
    </div>
  );
}

const useStyle = makeStyles({
  root: {
    padding: 10,
  },
  container: {
    minHeight: "100%",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "15px",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  paper: {
    minHeight: "90vh",
    borderRadius: 10,
    width: 1300,
  },
  paperContainer: {
    paddingRight: "5%",
    paddingLeft: "5%",
    display: "flex",
    justifyContent: "center",
  },
  paperHeader: {
    margin: 10,
    borderBottom: "1px solid lightGray",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    margin: 30,
  },
  seperator: {
    marginTop: 20,
  },
});
