import { Ansicht, changeAnsicht, selectContainerState } from "redux/container";
import { selectMeldungenState } from "redux/meldungen";
import { useAppDispatch, useAppSelector } from "redux/store";
import ChartButton from "./ChartButton";
import KachelnButton from "./KachelnButton";

export default function AnsichtToggle() {
  const dispatch = useAppDispatch();
  const containerState = useAppSelector(selectContainerState);
  const meldungenState = useAppSelector(selectMeldungenState);
  if (meldungenState.count === 0) return null;

  const onSelect = (ansicht: Ansicht) => {
    if (containerState.ansicht !== ansicht) {
      dispatch(changeAnsicht(ansicht));
    }
  };
  return (
    <div>
      <KachelnButton
        onSelect={() => onSelect("kacheln")}
        selected={containerState.ansicht === "kacheln"}
      />
      <ChartButton
        onSelect={() => onSelect("chart")}
        selected={containerState.ansicht === "chart"}
      />
    </div>
  );
}
