import env from "env";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  concat,
} from "@apollo/client";
import { SESSION_LOCAL_STORAGE_KEY } from "types/constants";

const httpLink = createHttpLink({
  uri: env.endpointSearch + "graphql",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-Session": localStorage.getItem(SESSION_LOCAL_STORAGE_KEY) || null,
    },
  }));

  return forward(operation);
});

export const gqlClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({ addTypename: false }),

  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});
