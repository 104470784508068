import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export default function ScrollToTopButton() {
  const [visible, setVisible] = useState(false);
  const classes = useStyle();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <button
      onClick={scrollToTop}
      className={classes.btn}
      style={{ display: visible ? "block" : "none" }}
    >
      {<ExpandLessIcon />}
    </button>
  );
}

const useStyle = makeStyles({
  btn: {
    backgroundColor: "rgb(204, 204, 204)",
    opacity: 0.7,
    transition: "opacity 0.4s ease 0s",
    zIndex: 1000,
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "center",
    boxAlign: "center",
    boxPack: "center",
    top: "96%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    right: "50%",
    position: "fixed",
    color: "white",
    "&:hover": {
      opacity: 1,
    },
  },
});
