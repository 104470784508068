import { Badge, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import { useAppDispatch, useAppSelector } from "redux/store";
import { exportMeldungen } from "redux/meldungen";
import { disabledColor, primaryColor } from "theme";
import FloatingPanel from "components/FloatingPanel";
import ListenImport from "components/ListenExport/ExportContent";
import { getExportListe, selectContainerState } from "redux/container";
import { unwrapResult } from "@reduxjs/toolkit";
import { showToast } from "redux/toast";
import { t } from "@lingui/macro";

export default function ExportButton() {
  const containerState = useAppSelector(selectContainerState);
  const dispatch = useAppDispatch();

  const classes = useStyle({
    clickable: containerState.exportliste.length > 0,
  });
  const [floatingPanelOpen, setFloatingPanelOpen] =
    React.useState<boolean>(false);

  const letzteAuftragsId = containerState.letzterExportierterAuftrag;
  let letzterAuftrag = containerState.auftraege.find(
    (x) => x.id === letzteAuftragsId
  );
  if (!letzterAuftrag) {
    letzterAuftrag = containerState.auftraege[0];
  }

  const handleClick = () => {
    if (containerState.exportliste.length > 0) setFloatingPanelOpen(true);
  };

  const handleClose = () => {
    setFloatingPanelOpen(false);
  };

  const onExport = async (auftragsId: string, labels: string[]) => {
    dispatch(
      exportMeldungen({
        meldungen: containerState.exportliste ?? [],
        auftragsId,
        labels,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        if (!result.erfolgreich) {
          dispatch(
            showToast({
              variant: "error",
              text: t({
                message: "Ihre Meldungen konnten nicht exportiert werden",
                id: "toast.failed.export",
              }),
            })
          );
        } else {
          dispatch(getExportListe());
          dispatch(
            showToast({
              variant: "success",
              text: t({
                message: "Ihre Meldungen wurden erfolgreich exportiert",
                id: "toast.success.export",
              }),
            })
          );
        }
      });
  };

  useEffect(() => {
    if (containerState?.exportliste?.length <= 0) {
      handleClose();
    }
  }, [containerState?.exportliste]);

  return (
    <>
      <Badge badgeContent={containerState.exportliste.length} color="secondary">
        <div onClick={handleClick}>
          <ScreenShareIcon
            color="primary"
            fontSize="large"
            className={classes.trigger}
          />
        </div>
      </Badge>
      <FloatingPanel
        open={floatingPanelOpen}
        position={"right"}
        onClose={handleClose}
      >
        <ListenImport
          auftraege={containerState.auftraege}
          letzterAuftrag={letzterAuftrag}
          onCancel={handleClose}
          onExport={onExport}
        />
      </FloatingPanel>
    </>
  );
}

const useStyle = makeStyles({
  logoContainer: {
    width: 95,
    height: 45,
  },
  underNavigationButton: {
    fontSize: 13,
    textTransform: "none",
    cursor: "pointer",
    width: "100%",
    alignSelf: "left",
  },
  navigationButton: {
    fontSize: 13,
    textTransform: "none",
    "&:hover": {
      color: "#00C1BE",
    },
    cursor: "pointer",
  },
  icon: {
    marginLeft: 10,
  },
  trigger: {
    cursor: (props) => (props["clickable"] ? "pointer" : ""),
    color: (props) => (props["clickable"] ? primaryColor : disabledColor),
  },
});
